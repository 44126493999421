import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useAbility } from '@casl/react';
import complaintsColumns from '../Profile/Complaint/complaintsColumns';
import ComplaintsDataTable from '../Profile/Complaint/ComplaintsDataTable';
import { LinkBtn } from '../../components';
import { AbilityContext } from '../../config/abilityContext';

const ComplaintIndex = () => {
  const ability = useAbility(AbilityContext);

  const complaintMenu = [
    ability.can('assign', 'Complaint'),
    ability.can('conclude', 'Complaint'),
    ability.can('read', 'Complaint'),
    ability.can('create', 'Complaint')
  ];

  return (
    <>
      <Row className="m-top mb-4">
        <Col>
          <h2 className="m-top mt-0">Denuncias</h2>
          <h4>Este canal tiene como objetivo facilitar la gestión de sus denuncias.</h4>
        </Col>
        {complaintMenu[3] && (
          <Col md={3}>
            <LinkBtn variant="primary" className="mt-3" block to="./complaint/new">
              Nuevo
            </LinkBtn>
          </Col>
        )}
      </Row>
      <ComplaintsDataTable columns={complaintsColumns} rrhh complaintMenu={complaintMenu} />
    </>
  );
};

export default ComplaintIndex;

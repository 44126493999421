import React, { useState, useEffect } from 'react';
import { withFormik, Form, Field, getIn } from 'formik';
import { Col, Row, Button } from 'react-bootstrap';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { debounceIndexEmployeesRequest } from '../../requests/employees';
import { FormikDatePicker, FormikSelect } from '../../components';

const ComplaintAssign = ({ errors, touched, setFieldValue, handleModalClose, isSubmitting }) => {
  const [employees, setEmployees] = useState([]);
  const today = moment();
  const isOutsideRange = day => day.isBefore(today) || day.isAfter(moment().add(30, 'days'));
  const dispatch = useDispatch();

  const fetchEmployees = () => {
    debounceIndexEmployeesRequest({
      dispatch,
      params: {
        active: true,
        sort_column: 'name',
        is_dt: false,
        display_length: 100
      },
      successCallback: response => {
        const dataToGroupSearch = [...response.data.data];
        setEmployees(response.data.data.length > 0 ? dataToGroupSearch : []);
      }
    });
  };

  useEffect(fetchEmployees, []);

  return (
    <Form>
      <Row>
        <Col>
          <Field name="complaint[assignedTo]">
            {({ field }) => (
              <FormikSelect
                {...field}
                label="Trabajador"
                options={employees}
                placeholder="Seleccione un trabajador"
                onChange={employee => {
                  setFieldValue('complaint[assignedTo]', employee.value);
                  setFieldValue('complaint[assignedToName]', employee.label);
                }}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Field name="complaint[responseDate]">
            {({ field }) => (
              <FormikDatePicker
                {...field}
                label="Fecha de Respuesta"
                placeholder="dd/mm/aaaa"
                isOutsideRange={isOutsideRange}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row className="justify-content-end mt-3">
        <Col md={{ span: 3, offset: 6 }}>
          <Button className="btn" variant="outline-info" block onClick={handleModalClose}>
            CANCELAR
          </Button>
        </Col>
        <Col md={3}>
          <Button type="submit" disabled={isSubmitting} block>
            CONFIRMAR
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  return {
    complaint: {
      id: props.complaint.id,
      assignedTo: '',
      assignedToName: '',
      responseDate: ''
    }
  };
};

const validationSchema = Yup.object().shape({
  complaint: Yup.object().shape({
    assignedTo: Yup.string().required('Debe seleccionar un trabajador.'),
    assignedToName: Yup.string().required(),
    responseDate: Yup.date()
      .required('Debe seleccionar una fecha.')
      .formatdate()
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit
})(ComplaintAssign);

import React, { useState, useEffect } from 'react';
import { Field, Form, getIn, withFormik } from 'formik';
import { Accordion, Col, Row, Button, Card } from 'react-bootstrap';
import * as Yup from 'yup';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Dropzone, FormikInput, FormikSelect, FormikDatePicker, NestedAttributes } from '../../../components';
import { complaintTypeTypes, relationshipTypes, locationTypes, yesOrNo } from './FormOptions';
import { createDropZoneFileRequest } from '../../../requests/dropzone';
import { debounceIndexEmployeesRequest } from '../../../requests/employees';
import './style.scss';

const today = moment().format('DD-MM-YYYY');

const WitnessesInput = ({ index, errors, touched }) => (
  <Col md={10}>
    <Field name={`complaint[witnessesAttributes][${index}][name]`}>
      {({ field }) => (
        <FormikInput
          {...field}
          abbr
          label="nombre del testigo"
          error={getIn(errors, field.name)}
          touched={getIn(touched, field.name)}
        />
      )}
    </Field>
  </Col>
);

const DenouncedEmployeeInput = ({ index, errors, touched }) => (
  <Col md={10}>
    <Field name={`complaint[denouncedEmployeesAttributes][${index}][name]`}>
      {({ field }) => (
        <FormikInput
          {...field}
          abbr
          label="Nombre del denunciado"
          error={getIn(errors, field.name)}
          touched={getIn(touched, field.name)}
        />
      )}
    </Field>
  </Col>
);

const ComplaintForm = ({ values, complaint, touched, errors, setFieldValue, isSubmitting, rrhh = false }) => {
  const [accordion, setAccordion] = useState('general');
  const [employees, setEmployees] = useState([]);
  const clientOrSupplier = ['client', 'supplier'].includes(values.complaint.relationship);
  const otherLocation = values.complaint.location === 'other';
  const withWitness = values.complaint.hasWitness === true;
  const { witnessesAttributes, denouncedEmployeesAttributes, documentKeys } = values.complaint;
  const { anonymous, complaintType, relationship, location, hasWitness } = complaint;
  const dispatch = useDispatch();

  const fetchEmployees = () => {
    debounceIndexEmployeesRequest({
      dispatch,
      params: {
        active: true,
        sort_column: 'name',
        is_dt: false,
        display_length: 100
      },
      successCallback: response => {
        const dataToGroupSearch = [...response.data.data];
        setEmployees(response.data.data.length > 0 ? dataToGroupSearch : []);
      }
    });
  };

  const onDropUploadedFile = documents => {
    documents.map((doc, index) => setFieldValue(`complaint[documentKeys][${index}]`, doc.code));
  };

  const handleDestroyFile = ({ code }) => {
    const newDocumentKeys = documentKeys.filter(dk => dk !== code);
    setFieldValue('complaint[documentKeys]', newDocumentKeys);
  };

  const handleOnDrop = (code, documents) => {
    const dropzoneFiles = {
      drop_zone_file: {
        code,
        documents
      }
    };
    return createDropZoneFileRequest({
      dispatch,
      params: dropzoneFiles,
      formData: true,
      successCallback: response => response
    });
  };

  const addWitnesses = () => {
    const mapWitnesses = witnessesAttributes.map((body, index) => {
      if (body._destroy) {
        return undefined;
      }

      return <WitnessesInput key={`witness-${index.toString()}`} index={index} errors={errors} touched={touched} />;
    });

    mapWitnesses.push(
      <>
        <Col md={10}>
          <FormikInput inputType="name" label="nombre del testigo" disabled />
        </Col>
      </>
    );

    return (
      <>
        <NestedAttributes
          mapInputs={mapWitnesses}
          arrayValues={witnessesAttributes}
          setFieldValue={setFieldValue}
          valuePath="complaint[witnessesAttributes]"
          newAttributes={{ name: '' }}
        />
      </>
    );
  };

  const addDenouncedEmployees = () => {
    const mapDenouncedEmployees = denouncedEmployeesAttributes.map((body, index) => {
      if (body._destroy) {
        return undefined;
      }

      return (
        <DenouncedEmployeeInput key={`denounced-${index.toString()}`} index={index} errors={errors} touched={touched} />
      );
    });

    mapDenouncedEmployees.push(
      <>
        <Col md={10}>
          <FormikInput inputType="name" label="nombre del denunciado" disabled />
        </Col>
      </>
    );

    return (
      <>
        <NestedAttributes
          mapInputs={mapDenouncedEmployees}
          arrayValues={denouncedEmployeesAttributes}
          setFieldValue={setFieldValue}
          valuePath="complaint[denouncedEmployeesAttributes]"
          newAttributes={{ name: '' }}
        />
      </>
    );
  };

  useEffect(fetchEmployees, []);

  return (
    <Form>
      <Accordion defaultActiveKey="general" className="ml-2 mt-2 mr-2">
        <Col className={`${accordion === 'general' ? 'top-header-green' : 'top-header-light-gray'} br-5`}>
          <Accordion.Toggle
            as={Col}
            eventKey="general"
            className="card-header-title"
            onClick={() => setAccordion('general')}
          >
            Datos Generales
          </Accordion.Toggle>
        </Col>
        <Accordion.Collapse eventKey="general">
          <Card.Body className="div-content">
            {rrhh && (
              <Row className="justify-content-start">
                <Col md={4}>
                  <Field name="complaint[employeeId]">
                    {({ field }) => (
                      <FormikSelect
                        {...field}
                        abbr
                        label="Colaborador"
                        options={employees}
                        placeholder="Seleccione un colaborador"
                        onChange={employee => {
                          setFieldValue('complaint[employeeId]', employee.value);
                        }}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
            )}
            <Row className="justify-content-start">
              <Col md={4}>
                <Field name="complaint[anonymous]">
                  {({ field }) => (
                    <FormikSelect
                      {...field}
                      abbr
                      defaultValue={anonymous}
                      label="¿Denuncia Anónima?"
                      placeholder="¿Denuncia Anónima?"
                      options={yesOrNo}
                      onChange={data => setFieldValue(field.name, data !== undefined ? data.value : '')}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
              <Col md={4}>
                <Field name="complaint[complaintType]">
                  {({ field }) => (
                    <FormikSelect
                      {...field}
                      abbr
                      defaultValue={complaintType}
                      placeholder="Tipo de Denuncia"
                      label="Tipo de Denuncia"
                      options={complaintTypeTypes}
                      onChange={data => setFieldValue(field.name, data?.value || '')}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
              <Col md={4}>
                <Field name="complaint[date]">
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      abbr
                      label="Fecha de la denuncia"
                      placeholder="dd/mm/aaaa"
                      value={today}
                      disabled
                    />
                  )}
                </Field>
              </Col>
            </Row>
          </Card.Body>
        </Accordion.Collapse>

        <Col className={`${accordion === 'details' ? 'top-header-green' : 'top-header-light-gray'} br-5 mt-2`}>
          <Accordion.Toggle
            as={Col}
            eventKey="details"
            className="card-header-title"
            onClick={() => setAccordion('details')}
          >
            Datos de la Denuncia
          </Accordion.Toggle>
        </Col>
        <Accordion.Collapse eventKey="details">
          <Card.Body className="div-content">
            <Row>
              <Col md={4}>
                <Field name="complaint[occurredAt]">
                  {({ field }) => (
                    <FormikDatePicker
                      {...field}
                      abbr
                      isOutsideRange={day => day.isAfter(moment())}
                      label="Fecha de Ocurrencia"
                      placeholder="dd/mm/aaaa"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
              <Col md={4}>
                <Field name="complaint[relationship]">
                  {({ field }) => (
                    <FormikSelect
                      {...field}
                      abbr
                      defaultValue={relationship}
                      placeholder="Relación con la(s) persona(s) denunciada(s)"
                      label="Relación con la(s) persona(s) denunciada(s)"
                      options={relationshipTypes}
                      onChange={data => setFieldValue('complaint[relationship]', data?.value || '')}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
              {clientOrSupplier && (
                <Col md={4}>
                  <Field name="complaint[denouncedPerson]">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        abbr
                        label="Nombre Cliente/Proveedor"
                        placeholder="Ingrese nombre"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
              )}
            </Row>
            <Row>
              <Col md={10}>
                <h4 className="text-uppercase">Personas(s) Denunciada(s)</h4>
                <div>{addDenouncedEmployees()}</div>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Field name="complaint[location]">
                  {({ field }) => (
                    <FormikSelect
                      {...field}
                      abbr
                      defaultValue={location}
                      label="Lugar donde ocurrió el hecho"
                      placeholder="Seleccione el lugar"
                      options={locationTypes}
                      onChange={data => setFieldValue('complaint[location]', data.value)}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
              {otherLocation && (
                <Col md={4}>
                  <Field name="complaint[locationDetails]">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        abbr
                        label="Detalles del Lugar"
                        placeholder="Especifique"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
              )}
            </Row>
            <Row>
              <Col md={4}>
                <Field name="complaint[hasWitness]">
                  {({ field }) => (
                    <FormikSelect
                      {...field}
                      abbr
                      defaultValue={hasWitness}
                      label="¿Tiene testigos de los hechos denunciados?"
                      placeholder="¿Tiene testigos?"
                      options={yesOrNo}
                      onChange={data => setFieldValue(field.name, data !== undefined ? data.value : '')}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
            </Row>
            {withWitness && (
              <Row>
                <Col md={10}>
                  <h4 className="text-uppercase">Testigo(s)</h4>
                  {addWitnesses()}
                </Col>
              </Row>
            )}
            <Row>
              <Col md={12}>
                <Field name="complaint[description]">
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      abbr
                      as="textarea"
                      label="Relato de los Hechos"
                      placeholder="Describa lo sucedido"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
            </Row>
          </Card.Body>
        </Accordion.Collapse>

        <Col className={`${accordion === 'evidences' ? 'top-header-green' : 'top-header-light-gray'} br-5 mt-2`}>
          <Accordion.Toggle
            as={Col}
            eventKey="evidences"
            className="card-header-title"
            onClick={() => setAccordion('evidences')}
          >
            Evidencias
          </Accordion.Toggle>
        </Col>
        <Accordion.Collapse eventKey="evidences">
          <Card.Body className="div-content">
            <Row>
              <Col md={12}>
                <p>Adjuntar documentos, imágenes o videos como evidencia.</p>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Dropzone
                  fileAccept=".pdf .xls .doc .docx .rtf .xlsx .ppt .pptx .jpg .jpeg .gif .mp3 .mp4 .wmv .avi .png"
                  maxSize={50000000}
                  multiple
                  customClass="long-dropzone"
                  onDelete={handleDestroyFile}
                  onDrop={handleOnDrop}
                  onDropUploaded={onDropUploadedFile}
                />
              </Col>
            </Row>
          </Card.Body>
        </Accordion.Collapse>
      </Accordion>

      <div className="mt-3 text-right">
        <Button type="submit" disabled={isSubmitting}>
          Crear Denuncia
        </Button>
      </div>
    </Form>
  );
};

const setInitialValues = props => {
  const {
    anonymous,
    complaintType,
    channel,
    relationship,
    location,
    occurredAt,
    denouncedPerson,
    denouncedEmployeesAttributes,
    description,
    hasWitness,
    locationDetails,
    date = today,
    witnessesAttributes,
    documentKeys,
    employeeId
  } = props.complaint;

  return {
    complaint: {
      anonymous,
      complaintType,
      channel,
      relationship,
      location,
      occurredAt,
      denouncedPerson,
      denouncedEmployeesAttributes,
      description,
      hasWitness,
      locationDetails,
      date,
      witnessesAttributes,
      documentKeys,
      employeeId
    }
  };
};

const mapPropsToValues = props => setInitialValues(props);

const validationSchema = Yup.object().shape({
  complaint: Yup.object().shape({
    anonymous: Yup.string().required('Debe seleccionar si la denuncia es anónima.'),
    complaintType: Yup.string().required('Debe seleccionar un tipo de denuncia.'),
    description: Yup.string()
      .required('Debe describir los hechos denunciados.')
      .max(1000, 'La descripción no puede superar los 1000 caracteres.'),
    employeeId: Yup.string().when('$rrhh', {
      is: true,
      then: Yup.string().required('Debe seleccionar un colaborador.')
    }),
    occurredAt: Yup.date()
      .transform((value, originalValue) =>
        moment(originalValue, 'DD/MM/YYYY', true).isValid() ? moment(originalValue, 'DD/MM/YYYY').toDate() : null
      )
      .required('Debe proporcionar la fecha de ocurrencia.')
      .typeError('Debe ser una fecha válida en formato DD/MM/AAAA.'),
    relationship: Yup.string().required('Debe especificar la relación con el denunciado.'),
    location: Yup.string().required('Debe especificar el lugar del hecho.'),
    denouncedPerson: Yup.string().when('relationship', {
      is: val => val === 'client' || val === 'supplier',
      then: Yup.string().required('Debe ingresar el nombre del Cliente/Proveedor.')
    }),
    locationDetails: Yup.string().when('complaint.location', {
      is: 'other',
      then: Yup.string()
        .min(5, 'Debe tener al menos 5 caracteres')
        .max(100, 'No puede exceder 100 caracteres.')
    }),
    witnessesAttributes: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('Debe ingresar el nombre del testigo.')
      })
    ),
    denouncedEmployeesAttributes: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('Debe ingresar el nombre del testigo.')
      })
    )
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(ComplaintForm);

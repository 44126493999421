import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexAdminAttendancesControlClockRequest = ({ dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', `/attendances`, {
    dispatch,
    params,
    successCallback,
    callback,
    service: 'control_clock'
  });

export const deactivateAdminAttendanceControlClockRequest = (
  id,
  { dispatch, params, formData, successCallback, failureCallback, callback }
) =>
  ApiService.request('get', `/attendances/${id}/change_status`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    service: 'control_clock'
  });

export const deactivateMassiveAdminAttendanceControlClockRequest = (
  { dispatch, params, formData, successCallback, failureCallback, callback }
) =>
  ApiService.request('get', `/attendances/massive_change_status`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    service: 'control_clock'
  });

export const exportAdminAttendancesControlClockRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback,
  callback
}) =>
  ApiService.request('get', `/attendances/export.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback,
    callback,
    service: 'control_clock'
  });

export const debounceIndexAdminAttendancesControlClockRequest = AwesomeDebouncePromise(
  indexAdminAttendancesControlClockRequest,
  300
);

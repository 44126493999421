import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Field, Form, getIn, withFormik } from 'formik';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { FormikCheckBox, FormikDatePicker, FormikInput, FormikSelect, SimpleCenteredModal } from '../../components';
import { debounceIndexShiftsRequest } from '../../requests/shifts';
import ShiftAssignmentsEmployees from './ShiftAssignmentsEmployees';
import ShiftInfo from '../Profile/Shift/ShiftInfo';

const ShiftAssignmentsForm = props => {
  const dispatch = useDispatch();
  const { errors, touched, setFieldValue, setFieldTouched, shiftAssignment, submitVariant, isSubmitting } = props;
  const [shifts, setShifts] = useState([]);
  const [shiftId, setShiftId] = useState();
  const [showCheckbox, setShowCheckBox] = useState(false);
  const [shiftStart, setShiftStart] = useState(new Date());
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);

  const handleStartDateChange = value => {
    const parsedDate = moment(value, 'DD/MM/YYYY').toDate();
    const formattedDate = moment(parsedDate).format('DD/MM/YYYY');
    setShiftStart(formattedDate);
  };

  const fetchShifts = () => {
    debounceIndexShiftsRequest({
      dispatch,
      params: {
        actives: true,
        sort_column: 'name',
        paginate: false
      },
      successCallback: response => {
        const cleanShifts = response.data.data.map(shift => ({
          label: shift.name,
          value: shift.id
        }));
        setShifts(cleanShifts);
      }
    });
  };

  function normalizeDate(date) {
    date.setHours(0, 0, 0, 0);
    return date;
  }

  const handleDateChange = value => {
    const selectedDate = normalizeDate(new Date(value));
    const currentDate = normalizeDate(new Date());
    if (selectedDate < currentDate) {
      setShowCheckBox(true);
    } else {
      setShowCheckBox(false);
    }
  };

  const isEndDateOutsideRange = date => {
    if (shiftStart) {
      return date < moment(shiftStart, 'DD/MM/YYYY').toDate();
    }
    return false;
  };

  const handleShowModal = () => {
    setModalTitle('');
    setModalShow(true);
    setModalBody(<ShiftInfo customId={shiftId} />);
  };

  const handleModalClose = () => {
    setModalShow(false);
  };

  useEffect(fetchShifts, []);

  return (
    <Form>
      <Row>
        <Col md={9}>
          <Field name="shiftAssignment[assignedShiftId]">
            {({ field }) => (
              <FormikSelect
                abbr
                label="Turno a Asignar"
                {...field}
                options={shifts}
                placeholder="Seleccione un Turno"
                onChange={value => {
                  setShiftId(value.value);
                  setFieldValue('shiftAssignment[assignedShiftId]', value);
                  setFieldTouched('shiftAssignment[assignedShiftId]', true);
                }}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={3} xl={2}>
          <Button type="button" className="mt-4" block onClick={handleShowModal} disabled={!shiftId}>
            Ver Turno
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Field name="shiftAssignment[shiftStart]">
            {({ field }) => (
              <FormikDatePicker
                abbr
                name={field.name}
                value={field.value}
                isOutsideRange={() => false}
                label="Inicio del Turno"
                placeholder="dd/mm/aaaa"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                onDateChange={value => {
                  if (!value) {
                    setFieldValue(field.name, '');
                    handleStartDateChange('');
                    handleDateChange('');
                  } else {
                    const formattedDate = moment(value).format('DD/MM/YYYY');
                    setFieldValue(field.name, formattedDate);
                    handleStartDateChange(formattedDate);
                    handleDateChange(value);
                  }
                }}
              />
            )}
          </Field>
        </Col>
        <Col md={4}>
          <Field name="shiftAssignment[shiftEnd]">
            {({ field }) => (
              <FormikDatePicker
                name={field.name}
                value={field.value}
                isOutsideRange={isEndDateOutsideRange}
                label="Fin del Turno"
                placeholder="dd/mm/aaaa"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        {showCheckbox && (
          <Col md={4} className="mt-4">
            <Field name="shiftAssignment[changeInCard]">
              {({ field }) => <FormikCheckBox {...field} field={field} label="Cambiar en Ficha" custom />}
            </Field>
          </Col>
        )}
      </Row>
      <Row>
        <Col md={12}>
          <Field name="shiftAssignment[observation]">
            {({ field }) => (
              <FormikInput
                {...field}
                label="Comentario"
                abbr
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      {shiftId && <ShiftAssignmentsEmployees shiftId={shiftId} shiftAssignment={shiftAssignment} />}
      <Row className="d-flex justify-content-end m-t-10 m-b-30">
        <Col md={3} xl={2}>
          <Button type="submit" className="btn mb-4" variant={submitVariant} disabled={isSubmitting}>
            Asignar Turno
          </Button>
        </Col>
      </Row>
      <SimpleCenteredModal title={modalTitle} body={modalBody} size="xl" show={modalShow} onHide={handleModalClose} />
    </Form>
  );
};

const validationSchema = Yup.object().shape({
  shiftAssignment: Yup.object().shape({
    assignedShiftId: Yup.string().required('Este campo es requerido'),
    shiftStart: Yup.date()
      .required('Debes seleccionar una fecha de inicio')
      .formatdate()
      .when(
        'shiftEnd',
        (shiftEnd, schema) => shiftEnd && schema.max(shiftEnd, 'Debe ser menor o igual a la fecha fin')
      ),
    observation: Yup.string()
      .required('Este campo es requerido')
      .min(5, 'El comentario debe tener al menos 5 caracteres')
      .max(255, 'El comentario debe tener máximo 255 caracteres')
  })
});

const setInitialValues = props => {
  return {
    shiftAssignment: {
      ...props.shiftAssignment
    }
  };
};

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  const newValues = {
    shiftAssignment: {
      ...values.shiftAssignment,
      assignedShiftId: values.shiftAssignment.assignedShiftId.value
    }
  };
  formRequest(newValues);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: false
})(ShiftAssignmentsForm);

/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';

import { ApproveRejectActions, ApprovementStatus, DatatableEmployeeName } from '../../../components';
import { handleDaysShow } from '../../../services/utils';

const columns = memoize(clickHandler => [
  {
    name: 'NOMBRE DEL TRABAJADOR',
    selector: 'employee',
    cell: item => <DatatableEmployeeName item={item.employee} onClick={() => clickHandler(item, 'show')} />,
    sortable: true,
    grow: '2'
  },
  {
    name: 'CARGO',
    selector: 'job_title',
    cell: item => item.employee?.job_title,
    sortable: true,
    grow: '1'
  },
  {
    name: 'FECHA DE SOLICITUD',
    selector: 'created_at',
    sortable: true,
    grow: '1'
  },
  {
    name: 'TIPO DE VACACIÓN',
    selector: 'vacation_type',
    cell: item => item.translated_vacation_type,
    sortable: true,
    grow: '1'
  },
  {
    name: 'FECHA INICIO',
    selector: 'start_date',
    sortable: true,
    grow: '1'
  },
  {
    name: 'FECHA TERMINO',
    selector: 'end_date',
    sortable: true,
    grow: '1'
  },
  {
    name: 'FECHA RETORNO',
    selector: 'reincorporation_date',
    sortable: true,
    grow: '1'
  },
  {
    name: 'TOTAL DÍAS',
    selector: 'days',
    cell: item => handleDaysShow(item, 'days'),
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => <ApprovementStatus status={item.status} onClick={() => clickHandler(item, 'show')} />,
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <ApproveRejectActions
        resource="Vacation"
        item={item}
        clickHandler={clickHandler}
        approveText={item.request_reviewer_signature ? 'Firmar' : 'Aprobar'}
        withDestroy
      />
    ),
    grow: '1',
    ignoreRowClick: true,
    allowOverflow: false,
    right: true,
    minWidth: '123px'
  }
]);

export default columns;

import React from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './style.scss';
import Icon from '../../Icons';

const SidebarDt = ({ children, pathName, closeSidebar }) => {
  return (
    <div className="scroll-box">
      <Nav className="flex-column" activeKey={pathName}>
        <Nav.Link eventKey="welcome" as={Link} to="/dt/welcome" onClick={closeSidebar}>
          <Icon icon="home-outline" />
          <span className="link-menu position-absolute">Bienvenido</span>
        </Nav.Link>
        <Nav.Link eventKey="legal_reports" as={Link} to="/dt/legal_reports" onClick={closeSidebar}>
          <Icon icon="menu" />
          <span className="link-menu position-absolute">Reportes DT</span>
        </Nav.Link>
        <Nav.Link eventKey="attendances_hash" as={Link} to="/dt/attendances_hash" onClick={closeSidebar}>
          <Icon icon="book-outline" />
          <span className="link-menu position-absolute">Busqueda por Hash</span>
        </Nav.Link>
        <Nav.Link
          eventKey="attendances_control_clock"
          as={Link}
          to="/dt/attendances_control_clock/true"
          onClick={closeSidebar}
        >
          <Icon icon="book-outline" />
          <span className="link-menu position-absolute">Incidentes Técnicos</span>
        </Nav.Link>
        <div className="d-lg-none">{children}</div>
      </Nav>
    </div>
  );
};

export default SidebarDt;

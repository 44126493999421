/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';

import { Button } from 'react-bootstrap';
import { ApprovementStatus, DatatableEmployeeName } from '../../../components';
import { handleDaysShow } from '../../../services/utils';

const columns = memoize((clickHandler, showActions, isAdministrativeDays) => [
  {
    name: 'NOMBRE DEL TRABAJADOR',
    selector: 'employee',
    cell: item => <DatatableEmployeeName item={item.employee} />,
    sortable: true,
    grow: '1',
    width: '200px',
    align: 'left'
  },
  {
    name: 'FECHA DE SOLICITUD',
    selector: 'created_at',
    sortable: true,
    grow: '1'
  },
  {
    name: isAdministrativeDays ? 'TIPO DE PERMISO' : 'TIPO DE VACACIÓN',
    selector: 'vacation_type',
    cell: item => item.translated_vacation_type,
    center: true,
    sortable: true,
    grow: '1'
  },
  {
    name: 'FECHA INICIO',
    selector: 'start_date',
    sortable: true,
    grow: '1'
  },
  {
    name: 'FECHA TÉRMINO',
    selector: 'end_date',
    sortable: true,
    grow: '1'
  },
  {
    name: 'FECHA RETORNO',
    selector: 'reincorporation_date',
    sortable: true,
    grow: '1'
  },
  {
    name: 'TOTAL DÍAS',
    selector: 'days',
    cell: item => handleDaysShow(item, 'days'),
    accessor: 'days',
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => <ApprovementStatus status={item.status} onClick={() => clickHandler(item, 'show')} />,
    grow: '1',
    center: true
  },
  {
    name: 'ACCIONES',
    cell: item => {
      return (
        <>
          {showActions && (
            <Button variant="pw" block onClick={() => clickHandler(item, 'actions')} size="sm">
              Gestionar
            </Button>
          )}
        </>
      );
    },
    grow: '1',
    ignoreRowClick: true,
    allowOverflow: false,
    right: true
  }
]);

export default columns;

import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import { LinkBtn, Icon } from '../../components';
import { sendAlert } from '../../actions/utils';
import ComplaintForm from '../Profile/Complaint/ComplaintForm';
import { createComplaintRequest } from '../../requests/complaints';
import basicComplaint from '../Profile/Complaint/complaint';

const ComplaintNewProfile = () => {
  const dispatch = useDispatch();
  const [moreData, setMoreData] = useState(false);
  const history = useHistory();

  const handleSuccessCreate = message => {
    dispatch(sendAlert({ kind: 'success', message }));
    setMoreData(!moreData);
  };

  const handleFailureRequest = error => {
    const { response } = error;
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const handleCreate = complaint => {
    createComplaintRequest({
      dispatch,
      params: snakeCaseKeys(complaint),
      successCallback: () => {
        handleSuccessCreate('Denuncia creada con éxito');
        history.push('/complaints');
      },
      failureCallback: handleFailureRequest
    });
  };

  return (
    <>
      <Row className="mt-4 mb-4">
        <Col xs={1}>
          <LinkBtn variant="circle-dark" className="btn-circle" block to="/complaints">
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        <Col>
          <h2 className="text-uppercase mb-0">Nueva Denuncia</h2>
        </Col>
      </Row>
      <Row className="main-div pb-3">
        <Col>
          <ComplaintForm action="new" complaint={basicComplaint} formRequest={handleCreate} rrhh />
        </Col>
      </Row>
    </>
  );
};

export default ComplaintNewProfile;

import React from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import { Button, Row, Col, Card } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import moment from 'moment';
import { FormikRangePicker, InputSelect, FormikSelect, FormikInput } from '../../../components';
import { markingTypes, statuses } from './formOptions';
import { debounceIndexShiftsRequest } from '../../../requests/shifts';
import { indexBranchOfficesRequest } from '../../../requests/branchOffices';
import useEmployeeSearch from '../../../services/useEmployeeSearchHook';
import './Style.scss';

const Filters = props => {
  const { errors, touched, setFieldValue, values, setFieldTouched, isSubmit } = props;
  const dispatch = useDispatch();

  const resultFetchData = (response, type) => {
    const result = response.data.data;
    return result.map(element => ({
      ...element,
      label: `${type ? `${element.code} - ` : ''} ${element.name}`,
      value: element.id
    }));
  };

  const { fetchEmployees } = useEmployeeSearch();

  const handleNewMulti = (data, field, allowEmpty = false) => {
    const newData = data.map(element => element.value);
    if (allowEmpty && !newData.length) newData.push('');
    setFieldValue(field, newData);
  };

  const fetchContractShift = (inputValue, callback) => {
    debounceIndexShiftsRequest({
      dispatch,
      params: {
        actives: true,
        query: inputValue,
        sort_column: 'name',
        display_length: 40
      },
      successCallback: data => {
        callback(resultFetchData(data));
      }
    });
  };

  const fetchBranchOffices = (inputValue, callback) => {
    indexBranchOfficesRequest({
      dispatch,
      params: {
        actives: true,
        query: inputValue,
        sort_column: 'name',
        paginate: false
      },
      successCallback: data => {
        callback(resultFetchData(data));
      }
    });
  };

  return (
    <Form>
      <Card style={{ zIndex: 'unset' }}>
        <Row>
          <Col className="mt-2">
            <Card className="card-dt">
              <Col className="top-header-green-dt card-header-title-dt">RANGO DE FECHAS INICIO DE JORNADA</Col>
              <Card.Body className="div-content card-body-padding">
                <Row>
                  <Col sm={12} md={4} xs={12}>
                    <Field name="rangeDate">
                      {({ field }) => (
                        <FormikRangePicker
                          {...field}
                          abbr
                          startDateName="dateFrom"
                          endDateName="dateTo"
                          startDate={values.dateFrom}
                          endDate={values.dateTo}
                          showClearDates
                          isOutsideRange={day => {
                            if (values.dateFrom) {
                              const payrollStartDate = moment(values.dateFrom, 'DD/MM/YYYY');
                              const payrollEndDate = moment(values.dateFrom, 'DD/MM/YYYY').add(60, 'days');
                              return day.isAfter(payrollEndDate) || day.isBefore(payrollStartDate);
                            }
                            return null;
                          }}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                        />
                      )}
                    </Field>
                  </Col>

                  <Col sm={12} md={4} xs={12}>
                    <Field name="timeType">
                      {({ field }) => (
                        <FormikSelect
                          {...field}
                          label="Tipo de Marcación"
                          placeholder="Seleccione"
                          options={markingTypes}
                          isClearable
                          onChange={data => setFieldValue(field.name, data ? data.value : '')}
                          setFieldTouched={() => setFieldTouched(field.name)}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>

                  <Col sm={12} md={4} xs={12}>
                    <Field name="status">
                      {({ field }) => (
                        <FormikSelect
                          {...field}
                          label="Estado"
                          placeholder="Seleccione"
                          options={statuses}
                          isClearable
                          onChange={data => setFieldValue(field.name, data ? data.value : '')}
                          setFieldTouched={() => setFieldTouched(field.name)}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Card>

      <Card style={{ zIndex: 'unset' }}>
        <Row>
          <Col className="mt-2">
            <Card className="card-dt">
              <Col className="top-header-green-dt card-header-title-dt">BUSQUEDA GRUPAL</Col>
              <Card.Body className="div-content card-body-padding">
                <Row>
                  <Col sm={12} md={4} xs={12}>
                    <Field name="employees">
                      {({ field }) => (
                        <InputSelect
                          {...field}
                          label="Colaboradores"
                          isMulti
                          placeholder="Seleccione colaboradores"
                          values={values.employee_ids}
                          request={fetchEmployees}
                          onChange={data => {
                            handleNewMulti(data || [], 'filter_ids', true);
                            setFieldValue(field.name, data || []);
                          }}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col sm={12} md={4} xs={12}>
                    <Field name="filterNationalId">
                      {({ field }) => (
                        <FormikInput
                          {...field}
                          label="N° de Identificación:"
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>

                  <Col sm={12} md={4} xs={12}>
                    <Field name="filterActiveBranchOffices">
                      {({ field }) => (
                        <InputSelect
                          {...field}
                          isMulti
                          label="Lugar de Prestacion de Servicios"
                          placeholder="Seleccionar"
                          value={undefined}
                          request={fetchBranchOffices}
                          onChange={data => handleNewMulti(data || [], field.name, true)}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Card>

      <Card className="card-dt">
        <Col className="top-header-green-dt card-header-title-dt">FILTRO POR TURNOS</Col>
        <Card.Body className="div-content card-body-padding">
          <Row>
            <Col sm={12} md={4} xs={12}>
              <Field name="shiftIds">
                {({ field }) => (
                  <InputSelect
                    {...field}
                    isMulti
                    label="Turno"
                    placeholder="Seleccionar Turno"
                    value={undefined}
                    request={fetchContractShift}
                    onChange={data => handleNewMulti(data || [], field.name, true)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Row className="d-flex justify-content-end mt-4 mb-4">
        <Col md={2}>
          <Button className="btn-block" type="submit" disabled={isSubmit}>
            Filtrar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const validationSchema = Yup.object().shape({
  rangeDate: Yup.array().when('filterOffday', {
    is: true,
    then: Yup.array().rangedate(true)
  })
});

const setInitialValues = props => {
  const { filterOffday = true } = props;
  return {
    dateFrom: '',
    dateTo: '',
    rangeDate: [],
    filterIds: [],
    shiftIds: [],
    filterNationalId: '',
    filterActiveBranchOffices: [],
    timeType: '',
    displayStart: 0,
    displayLength: 15,
    filterOffday
  };
};

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false
})(Filters);

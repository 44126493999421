import React from 'react';
import { Col, Row } from 'react-bootstrap';
import AttendancesControlClockDataTable from './AttendancesControlClockDataTable';

const AttendancesControlClockIndex = ({ match }) => {
  const isDt = match.params.is_dt;
  return (
    <>
      <Row className="mt-4 mb-1">
        <Col>
          <h2 className="text-uppercase mt-3">{isDt ? 'Incidentes Técnicos' : 'Log de Dispositivos'}</h2>
        </Col>
      </Row>
      <AttendancesControlClockDataTable isDt={isDt} />
    </>
  );
};

export default AttendancesControlClockIndex;

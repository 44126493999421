import { Form, withFormik } from 'formik';
import React from 'react';
import { Button, Row, Table } from 'react-bootstrap';
import * as Yup from 'yup';
import TableBodyForm from './OvertimeFormDates/TableBodyForm';
import { prepareDatesTosave } from './helpers';
import MovementDatesActions from './OvertimeFormDates/MovementDatesActions';
import styles from './styles.module.scss';

const OvertimeDatesForm = ({ overtimeGroup, values, isSubmitting, handleExportMovements }) => {
  const totalHours = (weekIndex, prop) => {
    const week = values?.overtimeGroup?.weekData[weekIndex];
    const totalTime = week.reduce(
      (acc, time) => {
        acc.hours += !Number.isNaN(Number(time[prop].hours)) ? Number(time[prop].hours) : 0;
        acc.minutes += !Number.isNaN(Number(time[prop].minutes)) ? Number(time[prop].minutes) : 0;
        return acc;
      },
      { hours: 0, minutes: 0 }
    );
    totalTime.hours += Math.floor(totalTime.minutes / 60);
    totalTime.minutes %= 60;
    return `${totalTime.hours.toString().padStart(2, '0')}:${totalTime.minutes.toString().padStart(2, '0')}:00`;
  };

  return (
    <>
      <Form>
        <div className={styles['custom-table--header']}>
          <MovementDatesActions handleClick={handleExportMovements} />
          <h6 className="ml-1 mb-0">{overtimeGroup.employee?.fullName}</h6>
        </div>
        {overtimeGroup?.weekData &&
          overtimeGroup?.weekData?.map((week, _index) => (
            <Table
              key={`${_index.toString()}_`}
              className="custom-data-table"
              bordered
              size="sm"
              style={{ fontWeight: 400 }}
            >
              <thead className="rdt_TableHeadRow" style={{ backgroundColor: '#EBEBEB', textTransform: 'uppercase' }}>
                <tr className="rdt_TableCol">
                  <th className="text-center align-middle">Fecha</th>
                  <th className="text-center align-middle">HHEE Autorizadas al Inicio</th>
                  <th className="text-center align-middle">HHEE Autorizadas al final</th>
                  <th className="text-center align-middle">Tiempo Extra</th>
                  <th className="text-center align-middle">Factor Hora Extra</th>
                  <th className="text-center align-middle">Atraso</th>
                  <th className="text-center align-middle">Salida Anticipada</th>
                  <th className="text-center align-middle">Exceso de Colacion</th>
                  <th className="text-center align-middle">Horas No trabajadas</th>
                  <th className="text-center align-middle">Saldo HHEE Compensadas</th>
                </tr>
              </thead>
              <tbody>
                {week.map((attendance, index) => (
                  <TableBodyForm key={attendance.date} attendance={attendance} dateIndex={_index} index={index} />
                ))}
              </tbody>
              <tfoot style={{ backgroundColor: '#EBEBEB' }}>
                <tr>
                  <td className="text-center align-middle"> Total Semanal </td>
                  <td className="text-center align-middle">{totalHours(_index, 'beginJourney')}</td>
                  <td className="text-center align-middle">{totalHours(_index, 'endJourney')}</td>
                  <td className="text-center align-middle">{totalHours(_index, 'calculatedOvertime')}</td>
                  <td className="text-center align-middle" />
                  <td className="text-center align-middle">{totalHours(_index, 'hoursLate')}</td>
                  <td className="text-center align-middle">{totalHours(_index, 'earlyDeparture')}</td>
                  <td className="text-center align-middle">{totalHours(_index, 'breakTimeExcess')}</td>
                  <td className="text-center align-middle">{totalHours(_index, 'totalNonWorked')}</td>
                  <td className="text-center align-middle">{totalHours(_index, 'compensatedHours')}</td>
                </tr>
              </tfoot>
            </Table>
          ))}
        <Row className="d-flex justify-content-end mt-2 mx-0">
          <Button type="submit" variant="primary" disabled={isSubmitting}>
            Guardar
          </Button>
        </Row>
      </Form>
    </>
  );
};

const initialValues = ({ overtimeGroup }) => {
  return {
    overtimeGroup: { ...overtimeGroup }
  };
};

const handleSubmit = (values, { props, setSubmitting }) => {
  const movements = prepareDatesTosave(values);
  const overtimeGroupObject = {
    overtimeDiscountsMovementsAttributes: movements
  };
  const { formRequest } = props;
  formRequest(overtimeGroupObject, setSubmitting);
};

const validationSchema = () => {
  return Yup.object().shape({
    overtimeGroup: Yup.object().shape({
      weekData: Yup.array().of(
        Yup.array().of(
          Yup.object().shape({
            calculatedOvertime: Yup.object().shape({
              hours: Yup.number().nullable(),
              minutes: Yup.number().nullable()
            })
          })
        )
      )
    })
  });
};

export default withFormik({
  mapPropsToValues: props => initialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true
})(OvertimeDatesForm);

import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexActivityAttendanceRequest = ({
  dispatch,
  params = {},
  format = '',
  responseType = 'json',
  failureCallback,
  successCallback
}) => {
  ApiService.request('get', `/activity_attendances${format}`, {
    dispatch,
    params,
    responseType,
    failureCallback,
    successCallback
  });
};

export const importTemplateActivityAttendanceRequest = ({ dispatch, params = {}, callback, successCallback }) => {
  ApiService.request('get', '/activity_attendances/import_template', {
    dispatch,
    params,
    responseType: 'text',
    callback,
    successCallback
  });
};

export const createActivityAttendanceRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', '/activity_attendances', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const exportActivityAttendanceRequest = ({ dispatch, params, failureCallback, successCallback, callback }) => {
  ApiService.request('get', `/activity_attendances/export.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback,
    callback
  });
};

export const importActivityAttendanceRequest = ({ dispatch, params, formData, failureCallback, successCallback, callback }) =>
  ApiService.request('post', '/activity_attendances/import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback,
    callback
  });

export const preImportActivityAttendanceRequest = ({ dispatch, params, formData, failureCallback, successCallback }) => {
  ApiService.request('get', '/activity_attendances/pre_import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const debounceIndexAttendanceRequest = AwesomeDebouncePromise(indexActivityAttendanceRequest, 300);

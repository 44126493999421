/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';

import { Icon, ButtonTooltip, ApprovementStatus, ApproveRejectActions } from '../../../components';
import { handleDaysShow } from '../../../services/utils';

const columns = memoize((clickHandler, ability) => [
  {
    name: 'TIPO DE VACACIÓN',
    selector: 'vacation_type',
    cell: item => item.translated_vacation_type,
    sortable: true,
    grow: '1'
  },
  {
    name: 'FECHA DE SOLICITUD',
    selector: 'created_at',
    sortable: true,
    grow: '1'
  },
  {
    name: 'FECHA INICIO',
    selector: 'start_date',
    cell: item => item.start_date,
    sortable: true,
    grow: '1'
  },
  {
    name: 'FECHA TERMINO',
    selector: 'end_date',
    cell: item => item.end_date,
    sortable: true,
    grow: '1'
  },
  {
    name: 'FECHA RETORNO',
    selector: 'reincorporation_date',
    cell: item => item.reincorporation_date,
    sortable: true,
    grow: '1'
  },
  {
    name: 'TOTAL DÍAS',
    selector: 'days',
    cell: item => handleDaysShow(item, 'days'),
    sortable: true,
    grow: '1'
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => <ApprovementStatus status={item.status} onClick={() => clickHandler(item, 'show')} />,
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'APROBAR',
    cell: item => (
      <ApproveRejectActions
        resource="Vacation"
        item={item}
        clickHandler={clickHandler}
        approveText={item.request_reviewer_signature ? 'Firmar' : 'Aprobar'}
      />
    ),
    grow: '1',
    center: true,
    ignoreRowClick: true,
    allowOverflow: false
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <>
        <ButtonTooltip
          onClick={() => clickHandler(item, 'show')}
          variant="circle-info"
          className="btn-circle"
          size="sm"
          text="Ver"
        >
          <Icon icon="eye" />
        </ButtonTooltip>
        {item.status === 'pending' && ability.can('destroy', 'Vacation') && (
          <ButtonTooltip
            onClick={() => clickHandler(item, 'destroy')}
            variant="circle-danger"
            className="btn-circle"
            size="sm"
            text="Eliminar"
          >
            <Icon icon="trash" />
          </ButtonTooltip>
        )}
      </>
    ),
    grow: '1',
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    minWidth: '123px'
  }
]);

export default columns;

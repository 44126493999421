import React from 'react';
import './style.scss';

import { CompaniesModal, SimpleCenteredModal } from '../../../components';

const SelectCurrentCompanyIndex = () => (
  <SimpleCenteredModal
    size="md"
    backdrop="static"
    title="Selecciona una empresa"
    closeButton={false}
    body={<CompaniesModal />}
    bodyClass="scroll-modal"
    show
  />
);

export default SelectCurrentCompanyIndex;

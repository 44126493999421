import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { LinkBtn } from '../../../components';
import complaintsColumns from './complaintsColumns';
import ComplaintsDataTable from './ComplaintsDataTable';

const ComplaintIndexProfile = () => {
  return (
    <>
      <Row className="m-top mb-4">
        <Col>
          <h2 className="m-top mt-0">Mis Denuncias</h2>
        </Col>
        <Col md={3}>
          <LinkBtn variant="primary" className="mt-3" block to="./complaint/new">
            Nuevo
          </LinkBtn>
        </Col>
      </Row>
      <ComplaintsDataTable columns={complaintsColumns} />
    </>
  );
};

export default ComplaintIndexProfile;

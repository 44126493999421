import React, { useEffect, useState } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import MarkingCorrectionDatatable from './MarkingCorrectionDatatable';
import MassiveCreateAttendanceForm from './MassiveActios/MassiveCreateAttendanceForm';
import columns from './columns';
import { SimpleCenteredModal } from '../../../components';
import { sendAlert } from '../../../actions/utils';

import { massiveCreateAttendance } from '../../../requests/AttendanceChange';
import { debounceIndexBranchOfficesRequest } from '../../../requests/branchOffices';

const MarkingCorrectionIndex = () => {
  const [moreData, setMoreData] = useState(false);
  const [simpleModalShow, setSimpleModalShow] = useState(false);
  const [simpleModalBody, setSimpleModalBody] = useState({});
  const [simpleModalTitle, setSimpleModalTitle] = useState('');
  const [branchOffices, setBranchOffices] = useState([]);
  const [size, setSize] = useState('lg');
  const { currentCompany } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  useEffect(() => window.scrollTo(0, 0), []);

  const fetchBranchOffices = () => {
    debounceIndexBranchOfficesRequest({
      dispatch,
      params: {
        actives: true,
        sort_column: 'name',
        paginate: false
      },
      successCallback: result => {
        setBranchOffices(result.data.data);
      }
    });
  };

  useEffect(fetchBranchOffices, []);

  const handleModalClose = () => {
    setSimpleModalShow(false);
  };

  const handleMassiveCreateAttendanceRequest = (attendance, setSubmitting) => {
    massiveCreateAttendance({
      dispatch,
      params: {
        ...snakeCaseKeys(attendance),
        front_company_id: currentCompany?.id
      },
      successCallback: () => {
        setMoreData(!moreData);
        dispatch(
          sendAlert({
            kind: 'success',
            message: 'Solicitud de Creación Masiva de Asistencia Realizada de Manera Correcta'
          })
        );
        handleModalClose();
      },
      failureCallback: error => {
        const { response } = error;
        dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
        setSubmitting(false);
      }
    });
    return true;
  };

  const handleMassiveCreateAttendance = () => {
    setSimpleModalBody(
      <MassiveCreateAttendanceForm
        formRequest={handleMassiveCreateAttendanceRequest}
        handleModalClose={handleModalClose}
        dispatch={dispatch}
        branchOffices={branchOffices}
      />
    );

    setSimpleModalTitle('Agregar Nueva Marcación');
    setSimpleModalShow(true);
    setSize('lg');

    return true;
  };
  return (
    <>
      <Row className="mt-4 mb-2">
        <Col md={9}>
          <h2 className="mb-4 mt-4">Corrección de Marcación</h2>
        </Col>
        <Col md={3} className="mt-3">
          <Button
            className="btn-block text-center"
            disabled={branchOffices.length === 0}
            onClick={() => handleMassiveCreateAttendance()}
          >
            NUEVA MARCACIÓN
          </Button>
        </Col>
      </Row>

      <MarkingCorrectionDatatable
        columns={columns}
        moreData={moreData}
        setMoreData={setMoreData}
        branchOffices={branchOffices}
      />

      <SimpleCenteredModal
        title={simpleModalTitle}
        body={simpleModalBody}
        size={size}
        show={simpleModalShow}
        onHide={handleModalClose}
      />
    </>
  );
};

export default MarkingCorrectionIndex;

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Spinner } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';
import BasicCard from '../Utils/Cards/BasicCard';
import { previredIndicatorsRequest } from '../../requests/indicators';
import IndicatorsForm from './IndicatorsForm';
import './style.scss';

const PreviredIndicators = () => {
  const percentFormatterCM = (style, minimumFractionDigits) => {
    const options = {
      minimumFractionDigits
    };
    if (style) {
      options.style = style;
    }
    return new Intl.NumberFormat('es-CL', options);
  };

  const dispatch = useDispatch();
  const [onRequest, setOnRequest] = useState(false);
  const [ufValue, setUfValue] = useState({
    ufDate: '--/--/--',
    uf: 0,
    lastMonthUfDate: '--/--/--',
    lastMonthUfValue: 0
  });
  const [utmAndUta, setUtmAndUta] = useState({
    utmMonth: '-- / --',
    utm: 0,
    uta: 0
  });
  const [maxTaxableRent, setMaxTaxableRent] = useState({
    afp: 0,
    ips: 0,
    retirement: 0
  });
  const [minTaxableRent, setMinTaxableRent] = useState({
    dependantAndIndependant: 0,
    underAndOverAge: 0,
    particular: 0,
    nonRemunerational: 0
  });
  const [apv, setApv] = useState({
    maxMonthly: 0,
    maxYearlong: 0
  });
  const [annualMaxAgreedDeposit, setAnnualMaxAgreedDeposit] = useState(0);
  const [afc, setAfc] = useState({
    undefinedEmployer: 0,
    undefinedEmployee: 0,
    definedEmployer: 0,
    definedEmployee: 0,
    undefinedMoreThanElevenYearsEmployer: 0,
    undefinedMoreThanElevenYearsEmployee: 0
  });

  const [monetaryCorrection, setMonetaryCorrection] = useState();

  const [afpQuotation, setAfpQuotation] = useState({
    capitalDependantAfp: 0,
    capitalDependantSis: 0,
    capitalIndependat: 0,
    cuprumDependantAfp: 0,
    cuprumDependantSis: 0,
    cuprumIndependat: 0,
    habitatDependantAfp: 0,
    habitatDependantSis: 0,
    habitatIndependat: 0,
    planvitalDependantAfp: 0,
    planvitalDependantSis: 0,
    planvitalIndependat: 0,
    providaDependantAfp: 0,
    providaDependantSis: 0,
    providaIndependat: 0,
    modeloDependantAfp: 0,
    modeloDependantSis: 0,
    modeloIndependat: 0,
    unoDependantAfp: 0,
    unoDependantSis: 0,
    unoIndependat: 0
  });
  const [houseHold, setHouseHold] = useState({
    sectionA: 0,
    sectionARequirement: 0,
    sectionB: 0,
    sectionBMinRequirement: 0,
    sectionBMaxRequirement: 0,
    sectionC: 0,
    sectionCMinRequirement: 0,
    sectionCMaxRequirement: 0,
    sectionD: 0,
    sectionDRequirement: 0
  });
  const [heavyLabor, setHeavyLabor] = useState({
    employer: 0,
    employee: 0,
    lightEmployer: 0,
    lightEmployee: 0
  });

  const [healthPercentages, setHealthPercentages] = useState({
    ccaf_percentage: 0.0,
    fonasa_percentage: 0.0
  });

  // hacer consulta de valores de uf con el current month
  const handleSuccessRequest = ({ data }) => {
    const parsedPreviredIndicators = camelCaseRecursive(data);
    const currencyCLPFormatter = new Intl.NumberFormat('es-CL', {
      style: 'currency',
      currency: 'CLP',
      minimumFractionDigits: 2
    });
    const percentFormatter = new Intl.NumberFormat('es-CL', {
      style: 'percent',
      minimumFractionDigits: 2
    });

    // Tratar y formatear data
    setUfValue({
      ufDate: parsedPreviredIndicators.ufDate,
      uf: currencyCLPFormatter.format(parsedPreviredIndicators.uf),
      lastMonthUfDate: parsedPreviredIndicators.lastMonthUfDate,
      lastMonthUfValue: currencyCLPFormatter.format(parsedPreviredIndicators.lastMonthUfValue)
    });

    setUtmAndUta({
      utmMonth: parsedPreviredIndicators.utmMonth,
      utm: currencyCLPFormatter.format(parsedPreviredIndicators.utm),
      uta: currencyCLPFormatter.format(parsedPreviredIndicators.uta)
    });

    setMaxTaxableRent({
      afp: currencyCLPFormatter.format(parsedPreviredIndicators.afpMaxTaxableRent),
      ips: currencyCLPFormatter.format(parsedPreviredIndicators.ipsMaxTaxableRent),
      retirement: currencyCLPFormatter.format(parsedPreviredIndicators.retirementMaxTaxableRent)
    });

    setMinTaxableRent({
      dependantAndIndependant: currencyCLPFormatter.format(parsedPreviredIndicators.dependantIndependantMinTaxableRent),
      underAndOverAge: currencyCLPFormatter.format(parsedPreviredIndicators.underOverAgeMinTaxableRent),
      particular: currencyCLPFormatter.format(parsedPreviredIndicators.particularMinTaxableRent),
      nonRemunerational: currencyCLPFormatter.format(parsedPreviredIndicators.nonRemunerationalMinTaxableRent)
    });

    setApv({
      maxMonthly: currencyCLPFormatter.format(parsedPreviredIndicators.maxMensualApvAmount),
      maxYearlong: currencyCLPFormatter.format(parsedPreviredIndicators.maxAnualApvAmount)
    });

    setAnnualMaxAgreedDeposit(currencyCLPFormatter.format(parsedPreviredIndicators.anualMaxAgreedDeposit));

    setAfc({
      undefinedEmployer: percentFormatter.format(parsedPreviredIndicators.undefinedContractEmployerAfc / 100),
      undefinedEmployee: percentFormatter.format(parsedPreviredIndicators.undefinedContractEmployeeAfc / 100),
      definedEmployer: percentFormatter.format(parsedPreviredIndicators.definedContractEmployerAfc / 100),
      definedEmployee: percentFormatter.format(parsedPreviredIndicators.definedContractEmployeeAfc / 100),
      undefinedMoreThanElevenYearsEmployer: percentFormatter.format(
        parsedPreviredIndicators.undefinedMore11YearsContractEmployerAfc / 100
      ),
      undefinedMoreThanElevenYearsEmployee: percentFormatter.format(
        parsedPreviredIndicators.undefinedMore11YearsContractEmployeeAfc / 100
      )
    });

    const monthOrder = [
      'january',
      'february',
      'march',
      'april',
      'may',
      'june',
      'july',
      'august',
      'september',
      'october',
      'november',
      'december'
    ];

    const sortedMonth = parsedPreviredIndicators.monetaryCorrection.sort((a, b) => {
      return monthOrder.indexOf(a.month) - monthOrder.indexOf(b.month);
    });

    setMonetaryCorrection(sortedMonth);

    setAfpQuotation({
      capitalDependantAfp: percentFormatter.format(parsedPreviredIndicators.capitalDependentAfpQuotationRate / 100),
      capitalDependantSis: percentFormatter.format(parsedPreviredIndicators.capitalDependentSisQuotationRate / 100),
      capitalIndependat: percentFormatter.format(parsedPreviredIndicators.capitalIndependentAfpQuotationRate / 100),
      cuprumDependantAfp: percentFormatter.format(parsedPreviredIndicators.cuprumDependentAfpQuotationRate / 100),
      cuprumDependantSis: percentFormatter.format(parsedPreviredIndicators.cuprumDependentSisQuotationRate / 100),
      cuprumIndependat: percentFormatter.format(parsedPreviredIndicators.cuprumIndependentAfpQuotationRate / 100),
      habitatDependantAfp: percentFormatter.format(parsedPreviredIndicators.habitatDependentAfpQuotationRate / 100),
      habitatDependantSis: percentFormatter.format(parsedPreviredIndicators.habitatDependentSisQuotationRate / 100),
      habitatIndependat: percentFormatter.format(parsedPreviredIndicators.habitatIndependentAfpQuotationRate / 100),
      planvitalDependantAfp: percentFormatter.format(parsedPreviredIndicators.planvitalDependentAfpQuotationRate / 100),
      planvitalDependantSis: percentFormatter.format(parsedPreviredIndicators.planvitalDependentSisQuotationRate / 100),
      planvitalIndependat: percentFormatter.format(parsedPreviredIndicators.planvitalIndependentAfpQuotationRate / 100),
      providaDependantAfp: percentFormatter.format(parsedPreviredIndicators.providaDependentAfpQuotationRate / 100),
      providaDependantSis: percentFormatter.format(parsedPreviredIndicators.providaDependentSisQuotationRate / 100),
      providaIndependat: percentFormatter.format(parsedPreviredIndicators.providaIndependentAfpQuotationRate / 100),
      modeloDependantAfp: percentFormatter.format(parsedPreviredIndicators.modeloDependentAfpQuotationRate / 100),
      modeloDependantSis: percentFormatter.format(parsedPreviredIndicators.modeloDependentSisQuotationRate / 100),
      modeloIndependat: percentFormatter.format(parsedPreviredIndicators.modeloIndependentAfpQuotationRate / 100),
      unoDependantAfp: percentFormatter.format(parsedPreviredIndicators.unoDependentAfpQuotationRate / 100),
      unoDependantSis: percentFormatter.format(parsedPreviredIndicators.unoDependentSisQuotationRate / 100),
      unoIndependat: percentFormatter.format(parsedPreviredIndicators.unoIndependentAfpQuotationRate / 100)
    });

    setHouseHold({
      sectionA: currencyCLPFormatter.format(parsedPreviredIndicators.sectionAHouseholdAllowance),
      sectionARequirement: currencyCLPFormatter.format(parsedPreviredIndicators.sectionARequirementHouseholdAllowance),
      sectionB: currencyCLPFormatter.format(parsedPreviredIndicators.sectionBHouseholdAllowance),
      sectionBMinRequirement: currencyCLPFormatter.format(
        parsedPreviredIndicators.sectionBMinRequirementHouseholdAllowance
      ),
      sectionBMaxRequirement: currencyCLPFormatter.format(
        parsedPreviredIndicators.sectionBMaxRequirementHouseholdAllowance
      ),
      sectionC: currencyCLPFormatter.format(parsedPreviredIndicators.sectionCHouseholdAllowance),
      sectionCMinRequirement: currencyCLPFormatter.format(
        parsedPreviredIndicators.sectionCMinRequirementHouseholdAllowance
      ),
      sectionCMaxRequirement: currencyCLPFormatter.format(
        parsedPreviredIndicators.sectionCMaxRequirementHouseholdAllowance
      ),
      sectionD: currencyCLPFormatter.format(parsedPreviredIndicators.sectionDHouseholdAllowance),
      sectionDRequirement: currencyCLPFormatter.format(parsedPreviredIndicators.sectionDRequirementHouseholdAllowance)
    });

    setHeavyLabor({
      employer: percentFormatter.format(parsedPreviredIndicators.heavyLaborEmployerFinance / 100),
      employee: percentFormatter.format(parsedPreviredIndicators.heavyLaborEmployeeFinance / 100),
      lightEmployer: percentFormatter.format(parsedPreviredIndicators.lightHeavyLaborEmployerFinance / 100),
      lightEmployee: percentFormatter.format(parsedPreviredIndicators.lightHeavyLaborEmployeeFinance / 100)
    });

    setHealthPercentages({
      ccaf_percentage: percentFormatter.format(parsedPreviredIndicators.ccafPercentage / 100),
      fonasa_percentage: percentFormatter.format(parsedPreviredIndicators.fonasaPercentage / 100)
    });

    setOnRequest(false);
  };

  const fetchIndicators = (indicatorsMonthFilter = null, indicatorsYearFilter = null, setSubmitting) => {
    setOnRequest(true);

    let params = {};

    if (indicatorsMonthFilter != null && indicatorsYearFilter != null) {
      params = {
        month: indicatorsMonthFilter,
        year: indicatorsYearFilter
      };
    }

    const request = async () => {
      await previredIndicatorsRequest({
        dispatch,
        params,
        successCallback: handleSuccessRequest,
        failureCallback: () => setOnRequest(false),
        callBack: () => setSubmitting(false)
      });
    };

    request();
  };

  useEffect(fetchIndicators, []);

  return (
    <>
      <IndicatorsForm formRequest={fetchIndicators} />
      {onRequest && (
        <div className="containerSpinnerLoad position-absolute">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      <Row>
        <Col xs={12} md={6} xl={6}>
          <BasicCard
            size="100%"
            title="Valor de la UF"
            divBody
            text={
              <table className="table table-borderless table-hover table-sm">
                <tbody>
                  <tr>
                    <td> al {ufValue.ufDate} </td>
                    <td className="text-end"> {ufValue.uf} </td>
                  </tr>
                  <tr>
                    <td> al {ufValue.lastMonthUfDate} </td>
                    <td className="text-end"> {ufValue.lastMonthUfValue} </td>
                  </tr>
                </tbody>
              </table>
            }
          />
          <BasicCard
            size="100%"
            title="UTM Y UTA"
            subtitle={`A ${utmAndUta.utmMonth}`}
            divBody
            text={
              <table className="table table-borderless table-hover table-sm">
                <tbody>
                  <tr>
                    <td> UTM </td>
                    <td className="text-end"> {utmAndUta.utm} </td>
                  </tr>
                  <tr>
                    <td> UTA </td>
                    <td className="text-end"> {utmAndUta.uta} </td>
                  </tr>
                </tbody>
              </table>
            }
          />
          <BasicCard
            size="100%"
            title="Rentas topes imponibles"
            divBody
            text={
              <table className="table table-borderless table-hover table-sm">
                <tbody>
                  <tr>
                    <td> Para afiliados a una AFP (87,8 UF) </td>
                    <td className="text-end"> {maxTaxableRent.afp} </td>
                  </tr>
                  <tr>
                    <td> Para afiliados al IPS (ex INP) (60 UF) </td>
                    <td className="text-end"> {maxTaxableRent.ips} </td>
                  </tr>
                  <tr>
                    <td> Para Seguro de Cesantía (131,8 UF) </td>
                    <td className="text-end"> {maxTaxableRent.retirement} </td>
                  </tr>
                </tbody>
              </table>
            }
          />
          <BasicCard
            size="100%"
            title="Rentas mínimas imponibles"
            divBody
            text={
              <table className="table table-borderless table-hover table-sm">
                <tbody>
                  <tr>
                    <td> Trab. Dependientes e Independientes </td>
                    <td className="text-end"> {minTaxableRent.dependantAndIndependant} </td>
                  </tr>
                  <tr>
                    <td> Menores de 18 y Mayores de 65 </td>
                    <td className="text-end"> {minTaxableRent.underAndOverAge} </td>
                  </tr>
                  <tr>
                    <td> Trabajadores de Casa Particular </td>
                    <td className="text-end"> {minTaxableRent.particular} </td>
                  </tr>
                  <tr>
                    <td> Para fines no remuneracionales </td>
                    <td className="text-end"> {minTaxableRent.nonRemunerational} </td>
                  </tr>
                </tbody>
              </table>
            }
          />
          <BasicCard
            size="100%"
            title="Ahorro Provisional Voluntario (APV)"
            divBody
            text={
              <table className="table table-borderless table-hover table-sm">
                <tbody>
                  <tr>
                    <td> Tope Mensual (50 UF) </td>
                    <td className="text-end"> {apv.maxMonthly} </td>
                  </tr>
                  <tr>
                    <td> Tope Anual (600 UF) </td>
                    <td className="text-end"> {apv.maxYearlong} </td>
                  </tr>
                </tbody>
              </table>
            }
          />
          <BasicCard
            size="100%"
            title="Depósito Convenido"
            divBody
            text={
              <table className="table table-borderless table-hover table-sm">
                <tbody>
                  <tr>
                    <td> Tope Anual (900 UF) </td>
                    <td className="text-end"> {annualMaxAgreedDeposit} </td>
                  </tr>
                </tbody>
              </table>
            }
          />
          <BasicCard
            size="100%"
            title="DISTRIBUCIÓN DEL 7% SALUD, PARA EMPLEADORES AFILIADOS A CCAF"
            divBody
            text={
              <table className="table table-borderless table-hover table-sm">
                <tbody>
                  <tr>
                    <td> CCAF </td>
                    <td className="text-end"> {healthPercentages.ccaf_percentage} R.I.</td>
                  </tr>
                  <tr>
                    <td> FONASA </td>
                    <td className="text-end"> {healthPercentages.fonasa_percentage} R.I.</td>
                  </tr>
                </tbody>
              </table>
            }
          />
        </Col>
        <Col xs={12} md={6} xl={6}>
          <BasicCard
            size="100%"
            title="Tasa Cotizacion Obligatoria AFP"
            divBody
            text={
              <table className="table table-borderless table-hover table-sm">
                <thead>
                  <tr>
                    <th scope="col" className="align-middle text-center" rowSpan={3}>
                      AFP
                    </th>
                    <th scope="col" className="text-center" colSpan={3}>
                      Tasa AFP Trabajadores
                    </th>
                  </tr>
                  <tr>
                    <th scope="col" className="text-center" colSpan={2}>
                      Dependientes
                    </th>
                    <th scope="col">Independientes</th>
                  </tr>
                  <tr>
                    <th scope="col" className="text-center">
                      Tasa AFP
                    </th>
                    <th scope="col" className="text-center">
                      SIS
                    </th>
                    <th scope="col" className="text-center">
                      Tasa AFP
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Capital </td>
                    <td className="text-center"> {afpQuotation.capitalDependantAfp} </td>
                    <td className="text-center"> {afpQuotation.capitalDependantSis} </td>
                    <td className="text-center"> {afpQuotation.capitalIndependat} </td>
                  </tr>
                  <tr>
                    <td> Cuprum </td>
                    <td className="text-center"> {afpQuotation.cuprumDependantAfp} </td>
                    <td className="text-center"> {afpQuotation.cuprumDependantSis} </td>
                    <td className="text-center"> {afpQuotation.cuprumIndependat} </td>
                  </tr>
                  <tr>
                    <td> Habitat </td>
                    <td className="text-center"> {afpQuotation.habitatDependantAfp} </td>
                    <td className="text-center"> {afpQuotation.habitatDependantSis} </td>
                    <td className="text-center"> {afpQuotation.habitatIndependat} </td>
                  </tr>
                  <tr>
                    <td> PlanVital </td>
                    <td className="text-center"> {afpQuotation.planvitalDependantAfp} </td>
                    <td className="text-center"> {afpQuotation.planvitalDependantSis} </td>
                    <td className="text-center"> {afpQuotation.planvitalIndependat} </td>
                  </tr>
                  <tr>
                    <td> ProVida </td>
                    <td className="text-center"> {afpQuotation.providaDependantAfp} </td>
                    <td className="text-center"> {afpQuotation.providaDependantSis} </td>
                    <td className="text-center"> {afpQuotation.providaIndependat} </td>
                  </tr>
                  <tr>
                    <td> Modelo </td>
                    <td className="text-center"> {afpQuotation.modeloDependantAfp} </td>
                    <td className="text-center"> {afpQuotation.modeloDependantSis} </td>
                    <td className="text-center"> {afpQuotation.modeloIndependat} </td>
                  </tr>
                  <tr>
                    <td> Uno </td>
                    <td className="text-center"> {afpQuotation.unoDependantAfp} </td>
                    <td className="text-center"> {afpQuotation.unoDependantSis} </td>
                    <td className="text-center"> {afpQuotation.unoIndependat} </td>
                  </tr>
                </tbody>
              </table>
            }
          />
          <BasicCard
            size="100%"
            title="Asignación Familiar"
            divBody
            text={
              <table className="table table-borderless table-hover table-sm">
                <thead>
                  <tr>
                    <th scope="col" className="align-middle">
                      Tramo
                    </th>
                    <th scope="col" className="text-center">
                      Monto
                    </th>
                    <th scope="col" className="text-center">
                      Requisitos de Renta
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Tramo A </td>
                    <td className="text-center"> {houseHold.sectionA} </td>
                    <td className="text-center"> Renta menor o igual a {houseHold.sectionARequirement} </td>
                  </tr>

                  <tr>
                    <td> Tramo B </td>
                    <td className="text-center"> {houseHold.sectionB} </td>
                    <td className="text-center">
                      {' '}
                      Renta entre {houseHold.sectionBMinRequirement} y {houseHold.sectionBMaxRequirement}{' '}
                    </td>
                  </tr>

                  <tr>
                    <td> Tramo C </td>
                    <td className="text-center"> {houseHold.sectionC} </td>
                    <td className="text-center">
                      {' '}
                      Renta entre {houseHold.sectionCMinRequirement} y {houseHold.sectionCMaxRequirement}{' '}
                    </td>
                  </tr>

                  <tr>
                    <td> Tramo D </td>
                    <td className="text-center"> {houseHold.sectionD} </td>
                    <td className="text-center"> Renta mayor a {houseHold.sectionDRequirement} </td>
                  </tr>
                </tbody>
              </table>
            }
          />
          <BasicCard
            size="100%"
            title="Cotización para trabajos pesados"
            divBody
            text={
              <table className="table table-borderless table-hover table-sm">
                <thead>
                  <tr>
                    <th scope="col">Puesto de Trabajo</th>
                    <th scope="col" className="text-center">
                      Empleador
                    </th>
                    <th scope="col" className="text-center">
                      Trabajador
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Trabajo Pesado 4% </td>
                    <td className="text-center"> {heavyLabor.employer} </td>
                    <td className="text-center"> {heavyLabor.employee} </td>
                  </tr>
                  <tr>
                    <td> Trabajo Menos Pesado 2% </td>
                    <td className="text-center"> {heavyLabor.lightEmployer} </td>
                    <td className="text-center"> {heavyLabor.lightEmployee} </td>
                  </tr>
                </tbody>
              </table>
            }
          />
          <BasicCard
            size="100%"
            title="Seguro de Cesantía (AFC)"
            divBody
            text={
              <table className="table table-borderless table-hover table-sm">
                <thead>
                  <tr>
                    <th scope="col">Contrato</th>
                    <th scope="col">Empleador</th>
                    <th scope="col">Trabajador</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Contrato Plazo Indefinido </td>
                    <td className="text-end"> {afc.undefinedEmployer} </td>
                    <td className="text-end"> {afc.undefinedEmployee} </td>
                  </tr>
                  <tr>
                    <td> Contrato Plazo Fijo: Empleador </td>
                    <td className="text-end"> {afc.definedEmployer} </td>
                    <td className="text-end"> {afc.definedEmployee} </td>
                  </tr>
                  <tr>
                    <td> Contrato Plazo Plazo Indefinido 11 años o más </td>
                    <td className="text-end"> {afc.undefinedMoreThanElevenYearsEmployer} </td>
                    <td className="text-end"> {afc.undefinedMoreThanElevenYearsEmployee} </td>
                  </tr>
                </tbody>
              </table>
            }
          />
          <BasicCard
            size="100%"
            title="Porcentajes y Factores de Actualización Directos"
            divBody
            text={
              <table className="table table-borderless table-hover table-sm">
                <thead>
                  <tr>
                    <th scope="col" className="text-center">
                      Mes
                    </th>
                    <th scope="col" className="text-center">
                      Año
                    </th>
                    <th scope="col" className="text-center">
                      Porcentaje
                    </th>
                    <th scope="col" className="text-center">
                      Factor
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {monetaryCorrection?.map(item => (
                    <tr key={item.id}>
                      <td className="text-center"> {item.traslateMonth} </td>
                      <td className="text-center"> {item.year} </td>
                      <td className="text-center">
                        {' '}
                        {percentFormatterCM('percent', 1).format(item.adjustmentPercentage / 100)}
                      </td>
                      <td className="text-center"> {percentFormatterCM(null, 3).format(item.directUpdateFactor)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default PreviredIndicators;

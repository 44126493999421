import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Spinner, Form as BSForm } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { useAbility } from '@casl/react';
import { FormikInput, Icon, MainLogo, UploadImage, CustomWebcam, CheckBoxBtn } from '../../components';
import { requestSignIn } from '../../actions/auth';
import { sendAlert } from '../../actions/utils';
import { resetPasswordRequest, checkIfUsernameExists, verifyTokenRequest } from '../../requests/utils';
import { AbilityContext } from '../../config/abilityContext';
import { updateAbility } from '../../config/ability';
import PasswordChecklist from './PasswordChecklist';

const ResetPassword = props => {
  const {
    onHide,
    submitVariant,
    errors,
    touched,
    currentEmployee,
    signedIn,
    user,
    history,
    location,
    setFieldValue,
    values
  } = props;

  const [newPassword, setNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [icon, setIcon] = useState('people');
  const [color, setColor] = useState('');
  const [useClass, setUseClass] = useState('');
  const [message, setMessage] = useState('');
  const [webcam, setWebcam] = useState(false);
  const ability = useAbility(AbilityContext);
  const dispatch = useDispatch();
  const view = new URLSearchParams(location.search).get('view');
  const companyName = new URLSearchParams(location.search).get('company_name');
  const { selectedImage } = values;
  const vSelectedImage = !selectedImage;

  const redirect = () => {
    if (signedIn) {
      updateAbility(ability);
      if (user.role === 'admin' && !currentEmployee) {
        history.replace('/admin/companies');
      } else if (user.role === 'dt' && !currentEmployee) {
        history.replace('/dt/welcome');
      } else {
        history.replace('/profile/dashboard');
      }
    }
  };

  const handleChange = (name, data) => {
    const inputValue = data.target.value;
    const cleanValue = inputValue.replace(/\s+/g, '');
    setFieldValue(name, cleanValue);
  };

  const handleInputPassword = e => {
    setNewPassword(e.target.value);
    setFieldValue(e.target.name, e.target.value);
  };

  const handleDisableButton = toogle => {
    setIsDisable(!toogle);
  };

  const handleWebCamButton = () => {
    setWebcam(!webcam);
  };

  const handleSuccessUsername = response => {
    const { data } = response;
    setIcon(data.icon);
    setColor(data.color);
    setUseClass(data.useClass);
    setMessage(data.message);
  };

  const handleCheckIfUsernameExists = value => {
    if (value && value.length > 3) {
      checkIfUsernameExists({
        dispatch,
        params: {
          username: value
        },
        successCallback: handleSuccessUsername
      });
    }
  };

  const handleVerifyToken = () => {
    const token = new URLSearchParams(location.search).get('token');
    verifyTokenRequest({
      dispatch,
      params: {
        token
      },
      failureCallback: error => {
        const { response } = error;
        dispatch(sendAlert({ kind: 'error', message: response?.data?.error }));
        history.replace('/login');
      },
      successCallback: () => {}
    });
  };

  useEffect(redirect, [signedIn, user, currentEmployee]);

  useEffect(handleVerifyToken, []);

  const handleFailureRequest = error => {
    const { response } = error;
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const handleEnrolled = () => {
    const token = new URLSearchParams(location.search).get('token');
    setTimeout(() => {
      if (view === 'enrolled') {
        resetPasswordRequest({
          dispatch,
          params: {
            token,
            view,
            company_name: companyName
          },
          formData: true,
          failureCallback: error => {
            handleFailureRequest(error, dispatch);
          },
          successCallback: result => {
            dispatch(sendAlert({ kind: 'success', message: result.data.message }));
            history.replace('/login');
          }
        });
      }
    }, 5000);
  };
  useEffect(handleEnrolled, []);
  return (
    <>
      {view === 'enrolled' && (
        <Row className="justify-content-center align-items-center h-100 img-login text-center">
          <Col sm={6} md={4}>
            <MainLogo size="20px" />
            <p>
              <strong style={{ fontSize: 14 }}>Estimado usuario, espere mientras procesamos su enrolamiento</strong>
            </p>
            <Spinner animation="border" variant="primary" />
          </Col>
        </Row>
      )}
      <Row className="justify-content-center align-items-center h-100">
        {(view === 'create' || view === 'reset') && (
          <Col md={4}>
            <Form className="d-flex flex-column justify-content-end mt-5">
              {view === 'create' && (
                <>
                  <h3 className="text-uppercase">CREAR USUARIO</h3>
                  <Field name="username" className="text-red">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        inputType="text"
                        placeholder="Indique un nombre de usuario"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        onBlur={() => handleCheckIfUsernameExists(values.username.trim())}
                        onChange={data => handleChange(field.name, data)}
                        rightBtn={<Icon icon={`${icon}`} color={`${color}`} height="20px" width="20px" />}
                        helpText={`${message}`}
                        classHelpText={`${useClass}`}
                      />
                    )}
                  </Field>
                </>
              )}
              {(view === 'create' || view === 'reset') && (
                <>
                  <h3 className="text-uppercase">{view === 'create' ? 'CREAR' : 'RECUPERAR'} CONTRASEÑA</h3>
                  <Field name="password">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        inputType={showPassword ? 'text' : 'password'}
                        placeholder="Contraseña"
                        onChange={e => handleInputPassword(e)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        rightBtn={<Icon icon="eye" height="20px" width="20px" />}
                        rightBtnClickHandler={() => setShowPassword(!showPassword)}
                      />
                    )}
                  </Field>
                  <Field name="passwordConfirmation">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        inputType={showPasswordConfirmation ? 'text' : 'password'}
                        placeholder="Confirmar Contraseña"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        rightBtn={<Icon icon="eye" height="20px" width="20px" />}
                        rightBtnClickHandler={() => setShowPasswordConfirmation(!showPasswordConfirmation)}
                      />
                    )}
                  </Field>
                  <PasswordChecklist
                    password={newPassword}
                    achieveRequirements={handleDisableButton}
                    user={user}
                    containerStyle={{
                      paddingBottom: 15
                    }}
                  />
                </>
              )}
              {view === 'create' && (
                <>
                  <h3 className="text-uppercase">CREAR PIN DE FIRMA DIGITAL</h3>
                  <Field name="passwordSignature">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        inputType="password"
                        placeholder="PIN"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                  <Field name="passwordSignatureConfirmation">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        inputType="password"
                        placeholder="Confirmar PIN"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                  <h3 className="text-uppercase">DATOS DEL COLABORADOR</h3>
                  <Field name="employeeProfileImage">
                    {({ field }) => (
                      <UploadImage
                        {...field}
                        circleImage
                        name="Adjuntar foto del colaborador."
                        onChange={avatar => setFieldValue(field.name, avatar)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        fileAccept=".png, .jpg, .jpeg"
                        helpText="Formato sugerido 400x400px de máximo 5mb."
                      />
                    )}
                  </Field>
                  <h3 className="text-uppercase">FIRMA DEL COLABORADOR</h3>
                  <BSForm.Group className="button-group max-width-group">
                    <Row>
                      <Field name="selectedImage">
                        {({ field }) => (
                          <CheckBoxBtn
                            {...field}
                            id="selectedImage"
                            label="Subir un Archivo"
                            checked={selectedImage}
                            onClick={handleWebCamButton}
                          />
                        )}
                      </Field>
                      <Field name="selectedImage">
                        {({ field }) => (
                          <CheckBoxBtn {...field} id="selectedImage" label="Tomar una Foto" checked={vSelectedImage} />
                        )}
                      </Field>
                    </Row>
                  </BSForm.Group>
                  {!webcam && (
                    <>
                      <Field name="employeeSignImage">
                        {({ field }) => (
                          <UploadImage
                            {...field}
                            circleImage
                            name="Adjuntar firma digital del colaborador."
                            onChange={avatar => setFieldValue(field.name, avatar)}
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                            fileAccept=".png, .jpg, .jpeg"
                            helpText="Formato sugerido 150x50px de máximo 5mb."
                          />
                        )}
                      </Field>
                    </>
                  )}
                  {webcam && (
                    <>
                      <Field name="employeeSignImage">
                        {({ field }) => (
                          <CustomWebcam
                            {...field}
                            onChange={avatar => setFieldValue(field.name, avatar)}
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                          />
                        )}
                      </Field>
                    </>
                  )}
                  <Field name="nationalIdSerial">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        abbr
                        label="Número de serie o documento"
                        inputType="text"
                        placeholder="123456789"
                        maxLength={9}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </>
              )}
              {(view === 'create' || view === 'reset') && (
                <>
                  <Button
                    variant={submitVariant}
                    size="lg"
                    className="mt-4 ml-auto"
                    type="submit"
                    onClick={onHide}
                    disabled={isDisable}
                  >
                    {view === 'create' ? 'CREAR' : 'RECUPERAR'} CONTRASEÑA
                  </Button>
                </>
              )}
            </Form>
          </Col>
        )}
      </Row>
    </>
  );
};

const mapStateToProps = state => {
  const { ongoingRequest, signedIn, currentEmployee, user } = state.auth;
  return {
    ongoingRequest,
    signedIn,
    currentEmployee,
    user
  };
};

const initialValues = props => {
  const { location } = props;
  const view = new URLSearchParams(location.search).get('view');

  return {
    username: '',
    password: '',
    passwordConfirmation: '',
    passwordSignature: '',
    passwordSignatureConfirmation: '',
    view,
    nationalIdSerial: '',
    employeeProfileImage: null,
    employeeSignImage: null,
    selectedImage: true
  };
};

const validationSchema = Yup.object().shape({
  password: Yup.string().when('view', {
    is: val => val === 'create' || val === 'reset',
    then: Yup.string().required('Este campo es obligatorio')
  }),
  passwordConfirmation: Yup.string().when('view', {
    is: val => val === 'create' || val === 'reset',
    then: Yup.string()
      .required('Este campo es obligatorio')
      .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden')
  }),

  username: Yup.string().when('view', {
    is: val => val === 'create',
    then: Yup.string()
      .test('no-spaces', 'No se permiten espacios en blanco', value => !/\s/.test(value))
      .required('Este campo es obligatorio')
      .min(4, 'El nombre de usuario debe ser de al menos 4 caracteres')
      .max(20, 'Máximo 20 caracteres permitidos')
  }),
  passwordSignature: Yup.string().when('view', {
    is: val => val === 'create',
    then: Yup.string()
      .required('Este campo es obligatorio')
      .min(6, 'La contraseña debe tener más de 6 caracteres')
  }),
  passwordSignatureConfirmation: Yup.string().when('view', {
    is: val => val === 'create',
    then: Yup.string()
      .required('Este campo es obligatorio')
      .min(6, 'La contraseña debe tener más de 6 caracteres')
      .oneOf([Yup.ref('passwordSignature'), null], 'Las contraseñas no coinciden')
  }),
  nationalIdSerial: Yup.string().when('view', {
    is: val => val === 'create',
    then: Yup.string()
      .min(8, 'El serial debe tener al menos 9 dígitos')
      .matches(/^[a-zA-Z0-9]*$/, 'Deben ser caracteres alfanuméricos')
  }),
  employeeProfileImage: Yup.mixed().when('view', {
    is: val => val === 'create',
    then: Yup.mixed()
      .nullable()
      .notRequired()
      .test(
        'FILE_SIZE',
        'La imagen cargada excede el tamaño máximo permitido (5 MB).',
        value => !value?.size || (value && value?.size <= 5242880)
      )
      .test(
        'FILE_FORMAT',
        'El archivo cargado tiene un formato no compatible.',
        value => (!value && !value?.type) || (value && ['image/jpg', 'image/png', 'image/jpeg'].includes(value?.type))
      )
      .test(
        'REPLACE_LETTER',
        'El archivo no debe tener la letra Ñ en su nombre',
        value => (!value && typeof (value?.name === 'undefined')) || (value && !value?.name.includes('ñ'))
      )
  }),
  employeeSignImage: Yup.mixed().when('view', {
    is: val => val === 'create',
    then: Yup.mixed()
      .test(
        'FILE_SIZE',
        'La imagen cargada excede el tamaño máximo permitido (5 MB).',
        value => !value?.size || (value && value?.size <= 5242880)
      )
      .test(
        'FILE_FORMAT',
        'El archivo cargado tiene un formato no compatible.',
        value => (!value && !value?.type) || (value && ['image/jpg', 'image/png', 'image/jpeg'].includes(value?.type))
      )
      .test(
        'REPLACE_LETTER',
        'El archivo no debe tener la letra Ñ en su nombre',
        value => (!value && typeof (value?.name === 'undefined')) || (value && !value?.name.includes('ñ'))
      )
  })
});

const handleFailureRequest = (error, dispatch) => {
  const { response } = error;
  dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
};

const handleSubmit = (values, { props }) => {
  const { dispatch, location } = props;
  const token = new URLSearchParams(location.search).get('token');
  const view = new URLSearchParams(location.search).get('view');

  resetPasswordRequest({
    dispatch,
    params: {
      username: values.username,
      password: values.password,
      password_confirmation: values.passwordConfirmation,
      password_signature: values.passwordSignature,
      password_signature_confirmation: values.passwordSignatureConfirmation,
      employee_national_id_serial: values.nationalIdSerial,
      employee_image: values.employeeProfileImage,
      employee_digitalsign: values.employeeSignImage,
      token,
      view
    },
    formData: true,
    failureCallback: error => {
      handleFailureRequest(error, dispatch);
    },
    successCallback: result => {
      const { user } = result.data;
      const login = user.role === 'basic' ? user.username : user.email;
      dispatch(sendAlert({ kind: 'success', message: result.data.message }));
      dispatch(
        requestSignIn({
          user: {
            login,
            password: values.password
          }
        })
      );
    }
  });
};

export default withRouter(
  connect(mapStateToProps)(
    withFormik({
      mapPropsToValues: props => initialValues(props),
      validationSchema,
      handleSubmit
    })(ResetPassword)
  )
);

import React, { useState, useEffect } from 'react';
import { Col, Row, Button, Spinner } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { sendAlert } from '../../actions/utils';
import { LinkBtn, Icon, DefaultModal, FormikInput, Dropzone } from '../../components';
import basicComplaint from '../Profile/Complaint/complaint';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import { createDropZoneFileRequest } from '../../requests/dropzone';
import InfoShow from '../Profile/Complaint/InfoShow';
import { showComplaintRequest, updateComplaintRequest } from '../../requests/complaints';

const ComplaintShow = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [complaint, setComplaint] = useState(basicComplaint);
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [loading, setLoading] = useState(true);
  const [modalConfirmBtn, setModalConfirmBtn] = useState('');
  const [modalConfirmBtnColor, setModalConfirmBtnColor] = useState('primary');
  const [newFiles, setNewFiles] = useState([]);
  const [destroyFiles, setDestroyFiles] = useState([]);
  const [comment, setComment] = useState('');

  const fetchComplaint = () => {
    setLoading(true);
    showComplaintRequest(id, {
      dispatch,
      successCallback: response => {
        setComplaint(camelCaseEmptyStringRecursive(response.data));
        setLoading(false);
      },
      failureCallback: () => {
        setLoading(false);
        history.push('/profile/complaints');
        dispatch(sendAlert({ kind: 'error', message: 'Denuncia no encontrada' }));
      }
    });
  };

  const handleSuccessAction = message => {
    dispatch(sendAlert({ kind: 'success', message }));
    setModalShow(false);
    fetchComplaint();
  };

  const handleFailureRequest = error => {
    const { response } = error;
    setModalShow(false);
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const handleUpdateComplaint = (params, isComment = false) => {
    const message = isComment ? 'Comentario agregado con éxito' : 'Denuncia actualizada con éxito';
    updateComplaintRequest(id, {
      dispatch,
      params,
      successCallback: () => handleSuccessAction(message),
      failureCallback: handleFailureRequest
    });
  };

  const handleAddComment = () => {
    handleUpdateComplaint({ complaint: { complaint_comments_attributes: [{ comment }] } }, true);
  };

  const handleUploadFiles = () => {
    const documentKeys = newFiles.filter(file => !destroyFiles.includes(file));
    handleUpdateComplaint({ complaint: { document_keys: documentKeys } });
  };

  const displayModal = (title, body, confirmBtn, confirmBtnColor) => {
    setModalTitle(title);
    setModalBody(body);
    setModalConfirmBtn(confirmBtn);
    setModalConfirmBtnColor(confirmBtnColor);
    setModalShow(true);
  };

  const modalHandleConfirm = () => {
    switch (modalTitle) {
      case 'Agregar Archivos':
        handleUploadFiles();
        break;
      case 'Agregar Comentario':
        handleAddComment();
        break;
      default:
        break;
    }
  };

  const onDropUploadedFile = documents => {
    const documentKeys = documents.map(doc => doc.code);
    setNewFiles(documentKeys);
  };

  const handleOnDrop = (code, documents) => {
    const dropzoneFiles = {
      drop_zone_file: {
        code,
        documents
      }
    };
    return createDropZoneFileRequest({
      dispatch,
      params: dropzoneFiles,
      formData: true,
      successCallback: response => response
    });
  };

  const handleDestroyFile = ({ code }) => {
    setDestroyFiles(prev => [...prev, code]);
  };

  const newComment = () => (
    <FormikInput as="textarea" label="Comentario" onChange={data => setComment(data.target.value)} />
  );

  useEffect(fetchComplaint, []);
  useEffect(() => window.scrollTo(0, 0), []);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  return (
    <>
      <Row className="mt-4 mb-4">
        <Col xs={1}>
          <LinkBtn variant="circle-dark" className="btn-circle" block to="/complaints">
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        <Col>
          <h2 className="text-uppercase mb-0">Detalles de la Denuncia</h2>
        </Col>
      </Row>

      <Row className="justify-content-end">
        <Col xs={3}>
          <Button
            block
            variant="primary"
            className="mb-4"
            onClick={() =>
              displayModal(
                'Agregar Archivos',
                <Dropzone
                  fileAccept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                  maxSize={50000000}
                  multiple
                  customClass="long-dropzone"
                  onDelete={handleDestroyFile}
                  onDrop={handleOnDrop}
                  onDropUploaded={onDropUploadedFile}
                />,
                'Agregar Archivos',
                'primary'
              )
            }
          >
            Agregar Archivos
          </Button>
        </Col>
        <Col xs={3}>
          <Button
            block
            variant="primary"
            className="mb-4"
            onClick={() => displayModal('Agregar Comentario', newComment(), 'Agregar Comentario', 'primary')}
          >
            Agregar Comentario
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <InfoShow complaint={complaint} />
        </Col>
      </Row>

      <DefaultModal
        body={modalBody}
        handleClose={() => setModalShow(false)}
        handleConfirm={modalHandleConfirm}
        modalSize="md"
        show={modalShow}
        title={modalTitle}
        titleBtnSave={modalConfirmBtn}
        variantBtnSave={modalConfirmBtnColor}
        withClose={false}
      />
    </>
  );
};

export default ComplaintShow;

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakecaseKeys from 'snakecase-keys';
import { ComponentDataTable, DefaultModal, SimpleCenteredModal } from '../../../components';
import {
  indexComplaintsRequest,
  deleteComplaintRequest,
  debounceIndexComplaintsAll,
  assignComplaintRequest,
  concludeComplaintRequest
} from '../../../requests/complaints';
import { sendAlert } from '../../../actions/utils';
import ComplaintAssign from '../../Complaint/ComplaintAssign';
import ComplaintConclusion from '../../Complaint/ComplaintConclusion';
import { camelCaseEmptyStringRecursive, delayMethod } from '../../../services/utils';

const ComplaintsDataTable = ({ columns, rrhh = false, complaintMenu = false }) => {
  const [onRequest, setOnRequest] = useState(true);
  const [complaints, setComplaints] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalItem, setModalItem] = useState(null);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [modalAction, setModalAction] = useState(() => null);
  const [simpleModalShow, setSimpleModalShow] = useState(false);
  const [simpleModalBody, setSimpleModalBody] = useState({});
  const [simpleModalTitle, setSimpleModalTitle] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();

  const handleModalClose = () => {
    setModalShow(false);
    setModalBody('');
    setOnRequest(false);
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message || 'Ocurrió un error' }));
    handleModalClose();
    setOnRequest(false);
  };

  const handleSuccessIndex = response => {
    const { data } = response;
    setComplaints(data.data);
    setOnRequest(false);
  };

  const handleRequest = async params => {
    setOnRequest(true);
    const request = rrhh ? debounceIndexComplaintsAll : indexComplaintsRequest;
    request({
      dispatch,
      params,
      successCallback: handleSuccessIndex
    });
  };

  const handleSuccessAction = message => {
    dispatch(sendAlert({ kind: 'success', message }));
    handleModalClose();
    setOnRequest(true);
    handleRequest();
  };

  const removeComplaint = item => {
    deleteComplaintRequest(item.id, {
      dispatch,
      successCallback: () => handleSuccessAction('Denuncia eliminada con éxito'),
      failureCallback: handleFailureRequest
    });
  };

  const handleConcludeComplaint = item => {
    concludeComplaintRequest(item.complaint.id, {
      dispatch,
      params: snakecaseKeys(item),
      successCallback: () => handleSuccessAction('Denuncia Cerrada con exito.'),
      failureCallback: handleFailureRequest
    });
    setModalShow(false);
  };

  const triggerConcludeConfirmModal = (item, conclusionData) => {
    setModalTitle('Confirmar Cierre');
    setModalBody(`¿Estás seguro de que deseas cerrar esta denuncia?`);
    setModalAction(() => () => handleConcludeComplaint(conclusionData));
    setModalShow(true);
  };

  const handleAssignComplaint = item => {
    assignComplaintRequest(item.complaint.id, {
      dispatch,
      params: snakecaseKeys(item),
      successCallback: () => handleSuccessAction('Denuncia actualizado con éxito'),
      failureCallback: handleFailureRequest
    });
    setModalShow(false);
  };

  const handleConcludeWithConfirmation = item => {
    setSimpleModalTitle('Cerrar Denuncia');
    setSimpleModalBody(
      <ComplaintConclusion
        handleModalClose={() => delayMethod(() => setSimpleModalShow(false))}
        complaint={camelCaseEmptyStringRecursive(item)}
        formRequest={conclusionData => {
          setSimpleModalShow(false);
          triggerConcludeConfirmModal(item, conclusionData);
        }}
      />
    );
    setSimpleModalShow(true);
  };

  const triggerAssignConfirmModal = (item, assignData) => {
    setModalTitle('Confirmar Asignación');
    setModalBody(
      `¿Estás seguro de que deseas asignar esta denuncia al colaborador ${assignData?.complaint.assignedToName}?`
    );
    setModalAction(() => () => handleAssignComplaint(assignData));
    setModalShow(true);
  };

  const handleAssignWithConfirmation = item => {
    setSimpleModalTitle('Asignar Denuncia');
    setSimpleModalBody(
      <ComplaintAssign
        handleModalClose={() => delayMethod(() => setSimpleModalShow(false))}
        complaint={camelCaseEmptyStringRecursive(item)}
        formRequest={assignData => {
          setSimpleModalShow(false);
          triggerAssignConfirmModal(item, assignData);
        }}
      />
    );
    setSimpleModalShow(true);
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'show':
        history.push(`./complaint/${item.id}`);
        break;
      case 'destroy':
        setModalTitle('Eliminar Denuncia');
        setModalItem(item);
        setModalBody(`¿Estás seguro de que deseas eliminar esta denuncia?`);
        setModalAction(() => removeComplaint);
        setModalShow(true);
        break;
      case 'assign':
        handleAssignWithConfirmation(item);
        break;
      case 'conclude':
        handleConcludeWithConfirmation(item);
        break;
      default:
        console.error('Acción no reconocida:', action);
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'date':
        return { sort_column: 'date' };
      case 'complaint_type':
        return { sort_column: 'complaint_type' };
      case 'channel':
        return { sort_column: 'channel' };
      case 'assignedTo':
        return { sort_column: 'assigned_to' };
      case 'responseDate':
        return { sort_column: 'response_date' };
      case 'status':
        return { sort_status: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        columns={columns(handleButtonClick, complaintMenu, rrhh)}
        data={complaints}
        handleSortCase={sortColumnCase}
        onRequest={onRequest}
        withStartDate
        withEndDate
        resourceRequest={handleRequest}
        onRowClicked={item => handleButtonClick(item, 'show')}
        pointerOnHover
      />
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        handleClose={handleModalClose}
        handleConfirm={() => modalAction(modalItem)}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
      <SimpleCenteredModal
        title={simpleModalTitle}
        body={simpleModalBody}
        show={simpleModalShow}
        onHide={() => setSimpleModalShow(false)}
      />
    </>
  );
};

export default ComplaintsDataTable;

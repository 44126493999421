import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexComplaintsRequest = ({ dispatch, params, successCallback, callback }) => {
  ApiService.request('get', '/complaints', {
    dispatch,
    params,
    successCallback,
    callback
  });
};

export const showComplaintRequest = (id, { dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', `/complaints/${id}`, {
    dispatch,
    params,
    successCallback,
    callback
  });

export const createComplaintRequest = ({ dispatch, params, formData, successCallback, callback }) =>
  ApiService.request('post', '/complaints', {
    dispatch,
    params,
    formData,
    successCallback,
    callback
  });

export const updateComplaintRequest = (id, { dispatch, params, formData, successCallback, failureCallback }) =>
  ApiService.request('put', `/complaints/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    showLeafArrayIndexes: true
  });

export const deleteComplaintRequest = (id, { dispatch, successCallback, callback }) =>
  ApiService.request('delete', `/complaints/${id}`, {
    dispatch,
    successCallback,
    callback
  });

export const debounceIndexComplaintsAll = ({ dispatch, params, successCallback, callback }) => {
  ApiService.request('get', '/complaints/index_all', {
    dispatch,
    params,
    successCallback,
    callback
  });
};

export const assignComplaintRequest = (id, { params, dispatch, successCallback, failureCallback }) => {
  ApiService.request('put', `/complaints/${id}/assign`, {
    dispatch,
    params,
    successCallback,
    failureCallback
  });
};

export const concludeComplaintRequest = (id, { params, dispatch, successCallback, failureCallback }) => {
  ApiService.request('put', `/complaints/${id}/conclude`, {
    dispatch,
    params,
    successCallback,
    failureCallback
  });
};

export const debounceIndexComplaintsRequest = AwesomeDebouncePromise(indexComplaintsRequest, 300);

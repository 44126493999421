import { Field, useFormikContext } from 'formik';
import React from 'react';

import InputMask from '../../../../components/Utils/Input/InputMask';

const TimeInput = ({ name, disabled, readOnlyInput }) => {
  const { setFieldValue } = useFormikContext();
  return (
    <Field name={`${name}[parsedTime]`} className="mb-0">
      {({ field }) => (
        <>
          <InputMask
            {...field}
            margin="mb-0"
            mask="99:99"
            readOnlyInput={readOnlyInput}
            disabled={disabled}
            onChange={event => {
              if (readOnlyInput) return;
              const { value } = event.target;
              const hours = value.split(':')[0];
              const minutes = value.split(':')[1];
              const formatedHours = hours > 23 ? 23 : hours;
              const formatedMinutes = minutes > 59 ? 59 : minutes;
              setFieldValue(`${name}[hours]`, +formatedHours);
              setFieldValue(`${name}[minutes]`, +formatedMinutes);
              setFieldValue(field.name, `${formatedHours}:${formatedMinutes}`);
            }}
            value={field.value}
          />
        </>
      )}
    </Field>
  );
};

export default TimeInput;

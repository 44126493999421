import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { ButtonTooltip, Icon } from '../../../components';
import './style.scss';

const InfoShow = ({ complaint }) => {
  const otherLocation = complaint.location === 'other';
  const clientOrSupplier = ['client', 'supplier'].includes(complaint.relationship);
  const isAnonymous = complaint.anonymous;
  const hasConclusion = complaint.conclusion;

  const renderAttribute = (label, value, md = 4) => (
    <>
      <Col md={md} className="mb-1">
        <Row>
          <Col>
            <p className="info title-sm font-weight-bold">{label}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="info">{value}</p>
          </Col>
        </Row>
      </Col>
    </>
  );

  const renderNestedAttributes = (label, attributes, keyField = 'id') => (
    <>
      <Col md={12}>
        <p className="info title-sm font-weight-bold">{label}</p>
      </Col>
      <Col md={12}>
        {attributes?.length > 0 ? (
          attributes.map((item, index) => (
            <p key={item[keyField] || `${label}-${index}`} className="info">
              {item.name}
            </p>
          ))
        ) : (
          <p>No hay información disponible.</p>
        )}
      </Col>
    </>
  );

  const renderComments = () => (
    <>
      {complaint.complaintComments.length > 0 ? (
        <div className="default-scroll-box comment-height">
          {complaint.complaintComments.map((comment, index) => (
            <div key={comment.id} className="mb-3">
              <p>
                <strong>{comment.employee.fullName}:</strong> {comment.comment}
              </p>
              <p className="text-muted">{comment.parsedCreatedAt}</p>
              {index !== complaint.complaintComments.length - 1 && <hr />}
            </div>
          ))}
        </div>
      ) : (
        <p>No hay comentarios aún.</p>
      )}
    </>
  );

  return (
    <>
      <Card>
        <Col className="top-header-green">Datos Generales</Col>
        <Card.Body>
          <Row>
            {renderAttribute('Código Único', complaint.uniqueCode)}
            {renderAttribute('Asignado', complaint.assignedToName ? complaint.assignedToName : 'Sin asignar')}
            {renderAttribute('Fecha de Resolución', complaint.responseDate ? complaint.responseDate : 'Sin asignar')}
          </Row>
          <Row>
            {renderAttribute('Denuncia Anónima', complaint.anonymous ? 'Sí' : 'No')}
            {renderAttribute('Tipo de Denuncia', complaint.translatedComplaintType)}
            {renderAttribute('Fecha de la Denuncia', complaint.date)}
          </Row>
          <Row>{!isAnonymous && renderAttribute('Denunciante', complaint.employee.fullName, 4)}</Row>
        </Card.Body>
      </Card>
      <Card>
        <Col className="top-header-green">Datos de la Denuncia</Col>
        <Card.Body>
          <Row>
            {renderAttribute('Fecha de Ocurrencia', complaint.occurredAt)}
            {renderAttribute('Relación', complaint.translatedRelationship)}
            {clientOrSupplier && renderAttribute('Nombre Cliente/Proveedor', complaint.denouncedPerson)}
          </Row>
          <Row>
            {complaint.denouncedEmployeesAttributes?.length > 0 &&
              renderNestedAttributes('Personas Denunciadas', complaint.denouncedEmployeesAttributes)}
          </Row>
          <Row>
            {renderAttribute('Lugar', complaint.translatedLocation)}
            {otherLocation && renderAttribute('Detalles del Lugar', complaint.locationDetails)}
            {renderAttribute('¿Hay Testigos?', complaint.hasWitness ? 'Sí' : 'No')}
          </Row>
          <Row>{complaint.hasWitness && renderNestedAttributes('Testigos', complaint.witnessesAttributes)}</Row>
          <Row>{renderAttribute('Relato', complaint.description, 12)}</Row>
        </Card.Body>
      </Card>
      <Card>
        <Col className="top-header-green">Evidencias</Col>
        <Card.Body>
          {complaint.infoFiles?.length > 0 ? (
            <Row>
              {complaint.infoFiles.map(file => (
                <Col md={4} key={file.id} className="mb-2">
                  <Row>
                    <Col xs={10}>
                      <p className="mb-0">{file.filename || 'Archivo sin nombre'}</p>
                    </Col>
                    <Col xs={2}>
                      <ButtonTooltip
                        variant="circle-primary"
                        className="pb-1"
                        onClick={() => window.open(file.fileUrl, '_blank', 'noopener')}
                        text={`Descargar ${file.filename || 'Archivo sin nombre'}`}
                      >
                        <Icon icon="download-2" size={20} />
                      </ButtonTooltip>
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          ) : (
            <p>No hay archivos adjuntos.</p>
          )}
        </Card.Body>
      </Card>
      <Card>
        <Col className="top-header-green">Comentarios</Col>
        <Card.Body>{renderComments('Comentarios', complaint.complaintComments)}</Card.Body>
      </Card>
      {hasConclusion && (
        <Card>
          <Col className="top-header-green">Resolución</Col>
          <Card.Body>
            <Row>{renderAttribute('Resolución', complaint.conclusion, 12)}</Row>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default InfoShow;

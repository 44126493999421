const complaintTypeTypes = [
  { value: 'sexual_harassment', label: 'Acoso Sexual' },
  { value: 'workplace_harassment', label: 'Acoso Laboral' },
  { value: 'workplace_violence', label: 'Violencia en el Trabajo' },
  { value: 'discrimination', label: 'Discriminación' }
];

const relationshipTypes = [
  { value: 'client', label: 'Cliente' },
  { value: 'supplier', label: 'Proveedor' },
  { value: 'company_employee', label: 'Colaborador Empresa' }
];

const locationTypes = [
  { value: 'company_facility', label: 'Instalaciones de la Empresa' },
  { value: 'client_supplier_facility', label: 'Instalaciones del Cliente/Proveedor' },
  { value: 'other', label: 'Otro' }
];

const yesOrNo = [
  { value: true, label: 'Sí' },
  { value: false, label: 'No' }
];

export { complaintTypeTypes, relationshipTypes, locationTypes, yesOrNo };

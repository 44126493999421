import React from 'react';
import { Col, ListGroup, Row, Image } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import Icon from '../../Icons';
import { selectCompanyRequest } from '../../../requests/auth';
import { sendAlert } from '../../../actions/utils';

const CompanyChildrensModal = ({ company, childrens }) => {
  const dispatch = useDispatch();

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const selectCompany = companyChildren => {
    selectCompanyRequest({
      dispatch,
      params: { current_company_id: companyChildren ? companyChildren.id : '' },
      successCallback: () => window.location.replace('/dt/welcome'),
      failureCallback: handleFailureRequest
    });
  };

  return (
    <>
      <p className="text-muted mb-3" style={{ fontWeight: 'bold' }}>
        La empresa seleccionada posee otras empresas relacionadas, puede escoger una de ellas. O continuar con la principal:
      </p><hr />
      <ListGroup variant="flush" className="mb-3">
        <ListGroup.Item
          key={company.id}
          className={company.id.toString() === company.id ? 'active' : ''}
          onClick={() => selectCompany(company)}
        >
          <Row>
            <Col xs={2} md={1} className="select-company p-0">
              {company.businessLogo?.fileUrl ? (
                <Image className="round-10" fluid width="38px" src={company.businessLogo.fileUrl} />
              ) : (
                <Icon icon="camera" width="38px" />
              )}
            </Col>
            <Col className="select-company d-flex align-items-center pl-2 pl-md-3">
              <div className="d-flex flex-column">
                <span className="text-muted">{company.businessName} - {company.nationalIdentification}</span>
              </div>
            </Col>
          </Row>
        </ListGroup.Item>
        {childrens.map(children => (
          <ListGroup.Item
            key={children.id}
            className={children.id.toString() === company.id ? 'active' : ''}
            onClick={() => selectCompany(children)}
          >
            <Row>
              <Col xs={2} md={1} className="select-company p-0">
                {children.businessLogo?.fileUrl ? (
                  <Image className="round-10" fluid width="38px" src={children.businessLogo.fileUrl} />
                ) : (
                  <Icon icon="camera" width="38px" />
                )}
              </Col>
              <Col className="select-company d-flex align-items-center pl-2 pl-md-3">
                <div className="d-flex flex-column">
                  <span className="text-muted">{children.businessName} - {children.nationalIdentification}</span>
                </div>
              </Col>
            </Row>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </>
  );
};
export default CompanyChildrensModal;

const basicComplaint = {
  assignedTo: '',
  responseDate: null,
  anonymous: false,
  complaintType: '',
  occurredAt: '',
  relationship: '',
  denouncedPerson: '',
  location: '',
  locationDetails: '',
  hasWitness: false,
  witnessesAttributes: [],
  denouncedEmployeesAttributes: [],
  description: '',
  channel: 'WEB',
  conclusion: '',
  closedAt: '',
  assignedAt: '',
  closedBy: '',
  documentKeys: []
};

export default basicComplaint;

import React, { useState, useEffect } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Row, Col, Button, Accordion, Card } from 'react-bootstrap';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import {
  FormikCheckBox,
  FormikDatePicker,
  FormikInput,
  FormikMaterialUiTimePicker,
  FormikNumber,
  FormikSelect,
  Icon
} from '../../components';
import ShiftContractsForm from './ShiftContractsForm';
import './style.scss';
import { shiftExtensions } from './FormOptions';

const ShiftHours = ({ errors, touched, setFieldValue, onHide, shift, action, values }) => {
  const [activeDays, setActiveDays] = useState([]);
  const [accordion, setAccordion] = useState('general');
  const daysOfWeek = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
  const {
    agreedSchedule,
    collationTime,
    exceptionalWorkday,
    flexibleShift,
    initialTime,
    lunchTime,
    shiftDaysAttributes,
    withAttendances,
    workHours
  } = values.shift;

  const disabled = !!withAttendances > 0;
  const location = useLocation();
  const isViewOnly = location.pathname.includes('/show');
  const isShiftHours = shift.shiftType === 'hours';

  const setInitialShiftDaysAttributes = () => {
    let vinitialActiveDays = [];
    if (action === 'new') {
      vinitialActiveDays = [...Array(7)].map(key => ({
        startTime: '09:00',
        endTime: '18:00',
        startLunch: '14:00',
        endLunch: '15:00',
        send: true,
        active: true,
        status: 'enabled',
        hours: '01:00',
        telecommuting: false,
        weekDay: key
      }));
    } else {
      vinitialActiveDays = shift.shiftDaysAttributes.map(item => ({ ...item, send: true }));
    }
    setActiveDays(vinitialActiveDays);
    setFieldValue('shift[activeDays]', vinitialActiveDays.length);
  };

  useEffect(setInitialShiftDaysAttributes, [shift]);

  const onClickDayCard = (item, index, element) => {
    if (element._dispatchListeners[0] === undefined) {
      const vActiveDays = activeDays;
      vActiveDays[index] = { ...activeDays[index], active: !item.active };
      setFieldValue(`shift[shiftDaysAttributes][${index}]`, vActiveDays[index]);
      setActiveDays(vActiveDays);
    }
  };

  useEffect(() => {
    if (lunchTime || collationTime) {
      const collationDuration = moment.duration(lunchTime ?? '0', 'minutes');
      const totalWorkDuration = moment.duration(workHours).add(collationDuration);
      const totalDuration = moment.duration(initialTime).add(totalWorkDuration);
      const endTimeWork = moment.utc(totalDuration.as('milliseconds')).format('HH:mm');
      shiftDaysAttributes.map((_item, index) =>
        setFieldValue(`shift[shiftDaysAttributes][${index}][endTime]`, endTimeWork)
      );
    }
  }, [lunchTime, collationTime, flexibleShift, initialTime, setFieldValue, shiftDaysAttributes, workHours]);

  return (
    <Form>
      <Accordion defaultActiveKey="0">
        <Card className="overflow-visible">
          <Col className={`${accordion === 'general' ? 'top-header-green' : 'top-header-light-gray'}`}>
            <Accordion.Toggle
              as={Col}
              eventKey="0"
              className="card-header-title-dt"
              onClick={() => setAccordion('general')}
            >
              Datos Generales
            </Accordion.Toggle>
          </Col>
          <Accordion.Collapse eventKey="0">
            <Card.Body className="div-content">
              <Row>
                <Col xs={12} md={6}>
                  <Field name="shift[name]">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        abbr
                        label="Nombre"
                        placeholder="Nombre de turno"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        disabled={disabled}
                      />
                    )}
                  </Field>
                </Col>
                <Col xs={12} md={3}>
                  <Field name="shift[acronym]">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        abbr
                        label="Siglas"
                        placeholder="Siglas del turno"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        disabled={disabled}
                      />
                    )}
                  </Field>
                </Col>
                <Col xs={12} md={3}>
                  <Field name="shift[shiftExtension]">
                    {({ field }) => (
                      <FormikSelect
                        {...field}
                        abbr
                        label="Extensión"
                        placeholder="Seleccionar"
                        options={shiftExtensions}
                        defaultValue={shift.shiftExtension}
                        isDisabled
                        onChange={data => {
                          setFieldValue(field.name, data ? data.value : '');
                        }}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={9}>
                  <Field name="shift[description]">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        abbr
                        label="Descripción"
                        placeholder="Descripción del turno"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        disabled={disabled}
                      />
                    )}
                  </Field>
                </Col>
                <Col xs={12} md={3} className="mt-4 d-flex align-items-center">
                  <Field name="shift[workHolidays]">
                    {({ field }) => (
                      <FormikCheckBox
                        {...field}
                        custom
                        field={field}
                        label="Trabaja festivos"
                        tooltipSize="23"
                        tooltipText="Turno con días festivos incluídos"
                        disabled={disabled}
                      />
                    )}
                  </Field>
                </Col>
              </Row>

              <Row>
                <Col xs={6} md={3} className="mt-4 d-flex align-items-center">
                  <Field name="shift[exceptionalWorkday]">
                    {({ field }) => (
                      <FormikCheckBox
                        {...field}
                        custom
                        field={field}
                        label="Jornada Excepcional"
                        tooltipSize="23"
                        disabled={disabled}
                      />
                    )}
                  </Field>
                </Col>
                {exceptionalWorkday && (
                  <>
                    <Col xs={6} md={3}>
                      <Field name="shift[workdayNumber]">
                        {({ field }) => (
                          <FormikInput
                            {...field}
                            label="Nro. Jornada"
                            placeholder="Número de jornada"
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                            disabled={disabled}
                          />
                        )}
                      </Field>
                    </Col>
                    <Col xs={6} md={3}>
                      <Field name="shift[beginExceptionalWorkday]">
                        {({ field }) => (
                          <FormikDatePicker
                            {...field}
                            isOutsideRange={() => false}
                            label="Inicio"
                            placeholder="dd/mm/aaaa"
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                            disabled={disabled}
                          />
                        )}
                      </Field>
                    </Col>
                    <Col xs={6} md={3}>
                      <Field name="shift[expirationExceptionalWorkday]">
                        {({ field }) => (
                          <FormikDatePicker
                            {...field}
                            isOutsideRange={() => false}
                            label="Vencimiento"
                            placeholder="dd/mm/aaaa"
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                            disabled={disabled}
                          />
                        )}
                      </Field>
                    </Col>
                  </>
                )}
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Col className={`${accordion === 'colation' ? 'top-header-green' : 'top-header-light-gray'}`}>
            <Accordion.Toggle
              as={Col}
              eventKey="1"
              className="card-header-title-dt"
              onClick={() => setAccordion('colation')}
            >
              Colación
            </Accordion.Toggle>
          </Col>
          <Accordion.Collapse eventKey="1">
            <Card.Body className="div-content">
              <Row>
                <Col xs={12} md={3} className="d-flex align-items-center">
                  <Field name="shift[imputableColation]">
                    {({ field }) => (
                      <FormikCheckBox
                        {...field}
                        custom
                        field={field}
                        label="Colación Imputable Jornada"
                        tooltipSize="23"
                        disabled={disabled}
                      />
                    )}
                  </Field>
                </Col>
                <Col xs={12} md={3} className="d-flex align-items-center">
                  <Field name="shift[lunchCheck]">
                    {({ field }) => (
                      <FormikCheckBox
                        {...field}
                        custom
                        field={field}
                        label="Marca colación"
                        tooltipSize="23"
                        tooltipText="Indica si deben marcar el ingreso y salida de colación"
                        disabled
                      />
                    )}
                  </Field>
                </Col>
                <Col xs={12} md={3} className="d-flex align-items-center">
                  <Field name="shift[agreedSchedule]">
                    {({ field }) => (
                      <FormikCheckBox
                        {...field}
                        custom
                        field={field}
                        label="Colación Horario Pactado"
                        tooltipSize="23"
                        tooltipText="Indica que deben marcar el ingreso y salida de colación en cada uno de los días seleccionados"
                        disabled={collationTime || flexibleShift || disabled}
                        onClick={() => {
                          setFieldValue(field.name, field.value === 'true' || field.value === true);
                          setFieldValue('shift[lunchTime]', 0);
                        }}
                      />
                    )}
                  </Field>
                </Col>
                <Col xs={12} md={3} className="d-flex align-items-center">
                  <Field name="shift[collationTime]">
                    {({ field }) => (
                      <FormikCheckBox
                        {...field}
                        custom
                        field={field}
                        label="Colación Tiempo Pactado"
                        tooltipSize="23"
                        disabled={agreedSchedule || disabled}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
              <Row>
                {collationTime && (
                  <>
                    <Col xs={12} md={3} className="d-flex align-items-center">
                      <Field name="shift[parsedLunchTime]">
                        {({ field }) => (
                          <FormikNumber
                            {...field}
                            abbr
                            rightAddon="min"
                            placeholder="0"
                            label="Colación"
                            fieldName="shift[lunchTime]"
                            value={values.shift.lunchTime}
                            tooltipSize="21"
                            tooltipText="Minutos permitidos para marcar ingreso y salida fuera del horario"
                            setFieldValue={setFieldValue}
                            errors={errors}
                            touched={touched}
                            disabled={disabled}
                          />
                        )}
                      </Field>
                    </Col>
                  </>
                )}
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Col className={`${accordion === 'distribution' ? 'top-header-green' : 'top-header-light-gray'}`}>
            <Accordion.Toggle
              as={Col}
              eventKey="2"
              className="card-header-title-dt"
              onClick={() => setAccordion('distribution')}
            >
              Distribución
            </Accordion.Toggle>
          </Col>
          <Accordion.Collapse eventKey="2">
            <Card.Body className="div-content">
              <Row>
                {activeDays.map((day, index) =>
                  day.active ? (
                    <Col
                      xs={5}
                      sm={2}
                      md={2}
                      key={`day-${index + 1}`}
                      className="shift-card-container-rotative mt-3"
                      onClick={e => onClickDayCard(day, index, e)}
                    >
                      <Row>
                        <Col md={12}>
                          <Icon
                            className="text-primary"
                            style={{ marginTop: '5px', position: 'absolute', left: '5px' }}
                            width={18}
                            icon="checkmark-circle"
                          />
                          <p className="text-uppercase shift-card-title">{daysOfWeek[index]}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="align-items-center" md={12}>
                          <p className="text-uppercase shift-card-label mt-3">Nro. Horas</p>
                          <Field name={`shift[shiftDaysAttributes][${index}][hours]`}>
                            {({ field }) => (
                              <FormikMaterialUiTimePicker
                                {...field}
                                abbr
                                defaultValue="01:00"
                                onChange={time => setFieldValue(field.name, time)}
                                error={getIn(errors, field.name)}
                                touched={getIn(touched, field.name)}
                                disabled={disabled}
                              />
                            )}
                          </Field>
                        </Col>
                        <Col md={12} className="mt-3" onClick={e => e.stopPropagation}>
                          <Field name={`shift[shiftDaysAttributes][${index}][telecommuting]`}>
                            {({ field }) => (
                              <FormikCheckBox
                                {...field}
                                custom
                                field={field}
                                label="Teletrabajo"
                                tooltipSize="23"
                                disabled={disabled}
                              />
                            )}
                          </Field>
                        </Col>
                      </Row>
                    </Col>
                  ) : (
                    <Col
                      key={`day-${activeDays.length + index + 1}`}
                      xs={5}
                      sm={2}
                      md={2}
                      className="shift-card-container-rotative-free mt-3"
                      onClick={e => disabled || onClickDayCard(day, index, e)}
                    >
                      <p className="text-uppercase shift-card-title text-center mt-4">{daysOfWeek[index]}</p>
                      <p className="text-uppercase text-center mb-4">Libre</p>
                    </Col>
                  )
                )}
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Col className={`${accordion === 'asignation' ? 'top-header-green' : 'top-header-light-gray'}`}>
            <Accordion.Toggle
              as={Col}
              eventKey="3"
              className="card-header-title-dt"
              onClick={() => setAccordion('asignation')}
            >
              Asignación
            </Accordion.Toggle>
          </Col>
          <Accordion.Collapse eventKey="3">
            <Card.Body className="div-content">
              <ShiftContractsForm shift={shift} />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>

      <Row className="d-flex justify-content-end my-3">
        {!(isViewOnly && isShiftHours) && (
          <Col md={3}>
            <Button type="submit" variant="primary" block onClick={onHide}>
              Guardar
            </Button>
          </Col>
        )}
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { shiftDaysAttributes } = props.shift;
  const shiftDaysLength = shiftDaysAttributes.length;

  const defaultShiftDay = {
    date: '',
    startTime: '09:00',
    endTime: '18:00',
    startLunch: '14:00',
    endLunch: '15:00',
    send: true,
    active: true,
    telecommuting: false,
    hours: '01:00',
    weekDay: ''
  };

  const shiftDays = shiftDaysLength
    ? shiftDaysAttributes.map(item => ({ ...item, send: true }))
    : Array.from({ length: 7 }, () => ({ ...defaultShiftDay }));

  return {
    shift: {
      ...props.shift,
      shiftDaysAttributes: shiftDays,
      shiftType: 'hours'
    }
  };
};

const parseDateString = dateString => {
  const [day, month, year] = dateString.split('/').map(Number);
  return new Date(year, month - 1, day);
};

const validationSchema = Yup.object().shape({
  shift: Yup.object().shape(
    {
      lunchCheck: Yup.boolean(),
      imputableColation: Yup.boolean(),
      workHolidays: Yup.boolean(),
      lunchTime: Yup.number().when('collationTime', {
        is: val => val,
        then: Yup.number()
          .required('Debes ingresar un número')
          .min(0, 'Debe ser mayor o igual a 0 '),
        otherwise: Yup.number().nullable()
      }),
      initialTime: Yup.string().when('flexibleShift', {
        is: val => val,
        then: Yup.string().required('Debes ingresar un horario'),
        otherwise: Yup.string().nullable()
      }),
      maximumStartTime: Yup.string().when('flexibleShift', {
        is: val => val,
        then: Yup.string().required('Debes ingresar tiempo máximo de inicio')
      }),
      workHours: Yup.string().when('flexibleShift', {
        is: val => val,
        then: Yup.string().required('Debes ingresar cantidad de horas a trabajar')
      }),
      collationTime: Yup.bool().when('agreedSchedule', {
        is: aSchedule => aSchedule === false,
        then: Yup.bool().oneOf([true], 'Debes seleccionar una opción')
      }),
      agreedSchedule: Yup.bool().when('collationTime', {
        is: colTime => colTime === false,
        then: Yup.bool().oneOf([true], 'Debes seleccionar una opción')
      }),
      name: Yup.string()
        .required('Debes ingresar un nombre')
        .max(80, 'Máximo 80 caracteres'),
      acronym: Yup.string()
        .required('Debes ingresar las siglas del turno')
        .max(5, 'Máximo 5 caracteres'),
      description: Yup.string()
        .required('Debes ingresar la descripción del turno')
        .max(80, 'Máximo 80 caracteres'),
      workdayNumber: Yup.string().when('exceptionalWorkday', {
        is: val => val === true,
        then: Yup.string()
          .required('Debes ingresar el número de jornada')
          .max(15, 'Máximo 15 caracteres')
      }),
      beginExceptionalWorkday: Yup.string().when('exceptionalWorkday', {
        is: val => val === true,
        then: Yup.string().required('Debes ingresar la fecha de inicio')
      }),
      expirationExceptionalWorkday: Yup.string().when('exceptionalWorkday', {
        is: val => val === true,
        then: Yup.string()
          .required('Debes ingresar la fecha de vencimiento')
          .test('is-greater', 'La fecha de vencimiento debe ser mayor que la fecha de inicio', function isGreaterTest(
            value
          ) {
            const { beginExceptionalWorkday } = this.parent;
            if (!beginExceptionalWorkday || !value) return true;
            const beginDate = parseDateString(beginExceptionalWorkday);
            const expirationDate = parseDateString(value);
            return expirationDate > beginDate;
          })
      })
    },
    [['agreedSchedule', 'collationTime']]
  )
});

const handleSubmit = (values, { props }) => {
  const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  const { formRequest } = props;
  const { shiftDaysAttributes } = values.shift;
  const parsedValues = shiftDaysAttributes.map((item, key) => ({ ...item, weekDay: daysOfWeek[key] }));
  const parsedShift = { ...values.shift, shiftDaysAttributes: parsedValues };
  formRequest({ shift: parsedShift });
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(ShiftHours);

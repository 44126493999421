import React from 'react';
import { withFormik, Form, Field, getIn } from 'formik';
import { Col, Row, Button } from 'react-bootstrap';
import * as Yup from 'yup';
import { FormikInput } from '../../components';

const ComplaintAssign = ({ errors, touched, handleModalClose, isSubmitting }) => {
  return (
    <Form>
      <Row className="mt-3">
        <Col>
          <Field name="complaint[conclusion]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                as="textarea"
                label="Resolución"
                placeholder="Resolución"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row className="justify-content-end mt-3">
        <Col md={{ span: 3, offset: 6 }}>
          <Button className="btn" variant="outline-info" block onClick={handleModalClose}>
            CANCELAR
          </Button>
        </Col>
        <Col md={3}>
          <Button type="submit" disabled={isSubmitting} block>
            CONFIRMAR
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  return {
    complaint: {
      id: props.complaint.id,
      conclusion: ''
    }
  };
};

const validationSchema = Yup.object().shape({
  complaint: Yup.object().shape({
    conclusion: Yup.string().required('Debe ingresar una resolución')
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit
})(ComplaintAssign);

import React, { useState, useEffect } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Modal, Spinner } from 'react-bootstrap';
import { FormikInput, FormikSelect } from '../../../components/index';
import { delayMethod } from '../../../services/utils';
import MapAttendance from '../../../components/Map/MapAttendance/MapAttendance';

const DashboardActivityAttendance = props => {
  const { errors, touched, setFieldValue, handleModalClose, isSubmitting } = props;
  const [geolocalitation, setGeolocalitation] = useState(null);

  const timeTypes = [
    { label: 'Entrada', value: 'arrival' },
    { label: 'Salida', value: 'departure' }
  ];

  const fetchGelocation = () => {
    let coordenates = null;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(({ coords: { latitude: lat, longitude: lng } }) => {
        coordenates = { lat, lng };
        setFieldValue('attendance[longitude]', lng)
        setFieldValue('attendance[latitude]', lat)

        setGeolocalitation(coordenates);
      });
    } else {
      setGeolocalitation(null);
    }
  };


  useEffect(fetchGelocation, []);


  const Longitude = data => {
    if (geolocalitation) {
      const latitude = data;
      latitude.field.value = geolocalitation.lng;
    }
    return null;
  };

  const Latitude = data => {
    if (geolocalitation) {
      const longitude = data;
      longitude.field.value = geolocalitation.lat;
    }
    return null;
  };

  return (
    <Form>
      <Modal.Body>
        <Row>
          <Col md={6}>
            <Field name="attendance[timeType]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  abbr
                  label="Tipo de Hora"
                  placeholder="Seleccionar Tipo de Hora"
                  options={timeTypes}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="attendance[activity]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  label="Actividad"
                  placeholder="Indicar la actividad"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={4}>
            <Field name="attendance[latitude]">{({ field }) => <Latitude field={field} />}</Field>
            <Field name="attendance[longitude]">{({ field }) => <Longitude field={field} />}</Field>
          </Col>
        </Row>
        <Row>
          <Col md={12} style={{ height: '30vh' }}>
            {geolocalitation ? (
              <MapAttendance myLocation={geolocalitation} />
            ) : (
              <>
                <p>Debe Aceptar la Geolocalización. De lo contrario, no se puede registrar su asistencia</p>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingTop: 80,
                    width: '100%'
                  }}
                >
                  <Spinner animation="border" variant="primary" />
                </div>
              </>
            )}
          </Col>
          <Col>*La dirección es referencial y no refleja la ubicación exacta</Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button className="text-center" variant="outline-info" onClick={() => delayMethod(handleModalClose)}>
          Cancelar
        </Button>
        {geolocalitation != null ? (
          <Button type="submit" variant="primary" disabled={isSubmitting}>
            Guardar
          </Button>
        ) : (
          <Button type="submit" variant="primary" disabled={geolocalitation === null}>
            Guardar
          </Button>
        )}
      </Modal.Footer>
    </Form>
  );
};

const setInitialValues = props => {
  const { attendances } = props;
  const attendance = attendances[0];

  return {
    attendance
  };
};

const validationSchema = Yup.object().shape({
  attendance: Yup.object().shape({
    date: Yup.string().required('Debes escoger una fecha'),
    time: Yup.string().required('Debes escoger la hora'),
    timeType: Yup.string().required('Debes escoger el tipo de hora'),
    employeeId: Yup.string().required('Debe haber un empleado asociado'),
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false
})(DashboardActivityAttendance);

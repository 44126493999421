import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { useDispatch } from 'react-redux';
import { showEmployeeCardsRequest } from '../../../requests/vacations';
import VacationDataTable from '../../../screens/Shared/Vacation/VacationDataTable';
import vacationColumns from '../../../screens/Shared/Vacation/watchColumns';
import VacationCard from '../../Vacation/VacationCard';
import ability from '../../../config/ability';
import VacationNew from '../../../screens/Profile/Vacation/VacationNew';
import { sendAlert } from '../../../actions/utils';
import { SimpleCenteredModal } from '../../Utils/Modal';

const EmployeeVacationTabs = ({ employee }) => {
  const [moreData, setMoreData] = useState(false);
  const dispatch = useDispatch();
  const { id: employeeId } = employee;
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalSubtitle, setModalSubtitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [legalVacationCard, setLegalVacationCard] = useState({});
  const [additionalVacationCard, setAdditionalVacationCard] = useState({});
  const [excessAccumulatedPeriods, setExcessAccumulatedPeriods] = useState(false);
  const [progressiveVacationCard, setProgressiveVacationCard] = useState({});

  const handleSuccessShow = response => {
    const { data } = response;

    setAdditionalVacationCard(camelCaseRecursive(data.additional_days_vacation_card));
    setExcessAccumulatedPeriods(camelCaseRecursive(data.legal_vacation_card).excessAccumulatedPeriods);
    setLegalVacationCard(camelCaseRecursive(data.legal_vacation_card));
    setProgressiveVacationCard(camelCaseRecursive(data.progressive_vacation_card));
  };

  const handleModalClose = () => {
    setModalShow(false);
    setModalTitle('');
    setModalBody('');
  };

  const setModal = (title, body) => {
    setModalShow(false);
    setModalTitle(title);
    setModalBody(body);
  };

  const handleSuccessAction = message => {
    dispatch(sendAlert({ kind: 'success', message }));
    setMoreData(!moreData);
    handleModalClose();
  };

  const handleFailureRequest = message => {
    dispatch(sendAlert({ kind: 'error', message }));
    setMoreData(!moreData);
    handleModalClose();
  };

  const handleNewElement = () => {
    setModal(
      'Agregar Vacaciones',
      <VacationNew
        employeeId={employeeId}
        onSuccess={handleSuccessAction}
        handleFailureAction={handleFailureRequest}
        handleModalClose={handleModalClose}
      />
    );
    setModalSubtitle(`Se encuentra solicitando vacaciones para: ${employee.fullName}`);
    setModalShow(true);
  };

  const fetchEmployeeCards = () => {
    if (employee) {
      showEmployeeCardsRequest({
        dispatch,
        params: {
          employee_id: employee.id
        },
        successCallback: handleSuccessShow
      });
    }
  };
  useEffect(fetchEmployeeCards, [employee]);

  return (
    <>
      <div className="d-block d-md-block">
        <Row className="mt-3 mb-4 mr-2">
          {ability.can('create', 'Vacation') && (
            <Col md={{ span: 2, offset: 10 }}>
              <Button variant="primary" block onClick={() => handleNewElement()}>
                Nuevo
              </Button>
            </Col>
          )}
        </Row>
        <Row className="mt-4">
          <Col md={4} lg={4}>
            <VacationCard
              title="Vacaciones"
              vacationCard={legalVacationCard}
              excessAccumulatedPeriods={excessAccumulatedPeriods}
              proportionalVacation
              rrhh
            />
          </Col>
          <Col md={4} lg={4}>
            <VacationCard title="Vacaciones Progresivas" vacationCard={progressiveVacationCard} />
          </Col>
          <Col md={4} lg={4}>
            <VacationCard title="Vacaciones Adicionales" vacationCard={additionalVacationCard} />
          </Col>
        </Row>
      </div>
      <VacationDataTable
        withMassActions
        withWorkflow={false}
        columns={vacationColumns}
        moreData={moreData}
        setMoreData={setMoreData}
        customParams={{
          filter_contract: employee?.contract?.id,
          employee_vacation: employee.id,
          filter_vacation_exception: 'administrative_days, administrative_days_openning_balance'
        }}
      />
      <SimpleCenteredModal
        title={modalTitle}
        subtitle={modalSubtitle}
        body={modalBody}
        show={modalShow}
        onHide={handleModalClose}
      />
    </>
  );
};

export default EmployeeVacationTabs;

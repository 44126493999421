import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import EmployeeData from '../../../components/Employee/Dashboard/EmployeeData';
import AttendanceData from '../../../components/Employee/Dashboard/AttendanceData';
import MyRequests from '../../../components/Employee/Dashboard/MyRequests';
import Benefits from '../../../components/Employee/Dashboard/Benefits';
import BirthCalendar from '../../../components/Employee/Dashboard/BirthCalendar';
import PendingDocuments from '../../../components/Employee/Dashboard/PendingDocuments';
import MyDocuments from '../../../components/Employee/Dashboard/MyDocuments';

const basicAttendance = (employeeId, companyId) => [
  {
    date: new Date(),
    time: new Date().toLocaleTimeString(),
    branchOfficeId: '',
    timeType: '',
    parsedTime: '',
    coordenates: {
      lat: -33.0011,
      lng: -70.11
    },
    employeeId,
    companyId
  }
];

const basicActivityAttendance = (employeeId, companyId) => [
  {
    date: new Date(),
    time: new Date().toLocaleTimeString(),
    timeType: '',
    parsedTime: '',
    latitude: -33.0011,
    longitude: -70.11,
    employeeId,
    companyId,
    activity: '',
    marking_type: "profile_web",
    device_type: "web"
  }
];

const MyProfileDashboardIndex = ({ companyName, currentTab, employeeName, user }) => {
  const { currentEmployee, currentCompany } = useSelector(state => state.auth);
  const [attendances, setAttendances] = useState(basicAttendance(currentEmployee, currentCompany.id));
  const [activityAttendances, setActivityAttendances] = useState(basicAttendance(currentEmployee, currentCompany.id));

  const [totalItemsRequest, setTotalItemsRequest] = useState(6);
  const { companyModules } = currentCompany;
  const peopleManagement = companyModules?.includes('people_management');
  const signature = companyModules?.includes('signature');

  useEffect(() => {
    let isMounted = true; // Flag to track if component is mounted

    if (isMounted) {
      setAttendances(basicAttendance(currentEmployee, currentCompany.id));
      setActivityAttendances(basicActivityAttendance(currentEmployee, currentCompany.id));

    }

    return () => {
      isMounted = false; // Cleanup function to set the flag to false when the component unmounts
    };
  }, [currentEmployee, currentCompany.id]);

  return (
    <>
      <Row className="main-div sm-12">
        <Col sm={6} md={6} className="mt-2">
          {employeeName && (
            <p className="title">
              Hola <b>{employeeName}</b>, bienvenido a <b>{companyName}</b>
            </p>
          )}
        </Col>
        <Col sm={12}>
          <Row>
            <Col sm={8} className="mt-2 mb-1">
              <EmployeeData user={user} />
            </Col>
            <Col sm={4} className="mt-2">
              <AttendanceData attendances={attendances} activityAttendances={activityAttendances} currentEmployee={currentEmployee} />
            </Col>
          </Row>

          <Row>
            <Col sm={4} className="mb-1 mt-2">
              <MyRequests
                currentEmployee={currentEmployee}
                widthSm={4}
                topClass="top-header-green"
                setTotalItemsRequest={setTotalItemsRequest}
              />
            </Col>
            {signature && (
              <Col sm={4} className="mb-1 mt-2">
                <PendingDocuments
                  topClass="top-header-light-gray"
                  tab="profile"
                  currentEmployee={currentEmployee}
                  totalItemsRequest={totalItemsRequest}
                  currentTab={currentTab}
                />
              </Col>
            )}
            {peopleManagement && (
              <Col sm={4} className="mb-1 mt-2">
                <MyDocuments />
              </Col>
            )}
          </Row>

          <Row>
            <Col sm={8} className="mb-1 mt-2">
              <Benefits />
            </Col>
            <Col sm={4} className="mb-2 mt-2">
              <BirthCalendar />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default MyProfileDashboardIndex;

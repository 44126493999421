import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Spinner } from 'react-bootstrap';
import { useAbility } from '@casl/react';
import moment from 'moment';
import { AbilityContext } from '../../config/abilityContext';
import {
  exportCostCentersRequest,
  importCostCenterRequest,
  importTemplateCostCenterRequest,
  preImportCostCenterRequest
} from '../../requests/costCenters';
import {
  exportBookkeepersRequest,
  importBookkeeperRequest,
  preImportBookkeeperRequest,
  importTemplateBookkeeperRequest
} from '../../requests/bookkeepers';
import {
  importBranchOfficeRequest,
  preImportBranchOfficeRequest,
  exportBranchOfficesRequest,
  importTemplateBranchOfficeRequest
} from '../../requests/branchOffices';
import {
  importSubsidiaryRequest,
  preImportSubsidiaryRequest,
  exportSubsidiariesRequest,
  importTemplateSubsidiaryRequest
} from '../../requests/subsidiaries';
import {
  importCertificationRequest,
  preImportCertificationRequest,
  exportCertificationsRequest,
  importTemplateCertificationRequest
} from '../../requests/certifications';
import {
  exportJobTitleRequest,
  importJobTitleRequest,
  importTemplateJobTitleRequest,
  preImportJobTitleRequest
} from '../../requests/jobTitles';
import {
  importJobManagementRequest,
  preImportJobManagementRequest,
  exportJobManagementsRequest,
  importTemplateJobManagementRequest
} from '../../requests/jobManagements';
import {
  importEmployeeRequest,
  preImportEmployeeRequest,
  exportEmployeesRequest,
  importTemplateEmployeeRequest
} from '../../requests/employees';
import {
  importFamilyGroupRequest,
  preImportFamilyGroupRequest,
  exportFamilyGroupsRequest,
  importTemplateFamilyGroupRequest
} from '../../requests/familyGroups';
import {
  importEmployeePrevisionRequest,
  preImportEmployeePrevisionRequest,
  exportEmployeePrevisionsRequest,
  importTemplateEmployeePrevisionRequest
} from '../../requests/employeePrevisions';
import {
  importContractRequest,
  preImportContractRequest,
  exportContractsRequest,
  importTemplateContractRequest
} from '../../requests/contracts';
import {
  importBalanceRequest,
  preImportBalanceRequest,
  exportBalancesRequest,
  importTemplateBalanceRequest
} from '../../requests/balances';
import {
  importContractBalanceRequest,
  preImportContractBalanceRequest,
  exportContractBalancesRequest,
  importTemplateContractBalanceRequest
} from '../../requests/contractBalances';
import {
  importShiftRequest,
  preImportShiftRequest,
  importTemplateShiftRequest,
  exportShiftRequest
} from '../../requests/shifts';
import {
  exportAbsencesRequest,
  importAbsenceRequest,
  importTemplateAbsenceRequest,
  preImportAbsenceRequest
} from '../../requests/absences';
import {
  exportPermissionsRequest,
  importPermissionRequest,
  importTemplatePermissionRequest,
  preImportPermissionRequest
} from '../../requests/permissions';
import {
  exportVacationsRequest,
  importVacationRequest,
  importTemplateVacationRequest,
  preImportVacationRequest
} from '../../requests/vacations';
import {
  exportLoansRequest,
  importLoanRequest,
  importTemplateLoanRequest,
  preImportLoanRequest
} from '../../requests/loans';
import {
  exportAdvancePaymentsRequest,
  importAdvancePaymentRequest,
  importTemplateAdvancePaymentRequest,
  preImportAdvancePaymentRequest
} from '../../requests/advancePayments';
import {
  exportAttendanceRequest,
  importAttendanceRequest,
  importTemplateAttendanceRequest,
  preImportAttendanceRequest
} from '../../requests/attendances';
import { sendAlert } from '../../actions/utils';
import { downloadFile } from '../../services/utils';
import { ImporterCard, DefaultModal, SimpleCenteredModal, ImportModal } from '../../components';
import AttendanceFilterForm from './Attendances/AttendanceFilterForm';
import { exportActivityAttendanceRequest, importActivityAttendanceRequest, importTemplateActivityAttendanceRequest, preImportActivityAttendanceRequest } from '../../requests/activityAttendances';

const ImporterIndex = () => {
  const { advanceSettings } = useSelector(state => state.utils);
  const { currentCompany } = useSelector(state => state.auth);
  const [modalShow, setModalShow] = useState(false);
  const [sendNotifications, setSendNotifications] = useState(false);
  const [alertModalShow, setAlertModalShow] = useState(false);
  const [simpleModalShow, setSimpleModalShow] = useState(false);
  const [onRequest, setOnRequest] = useState(false);
  const [modalAction, setModalAction] = useState(() => function empty() { });
  const [onDropUploaded, setOnDropUploaded] = useState(() => function empty() { });
  const [handleTemplate, setHandleTemplate] = useState(() => function empty() { });
  const [handleImport, setHandleImport] = useState(() => function empty() { });
  const [params, setParams] = useState({});
  const [currentCard, setCurrentCard] = useState({
    title: '',
    alertInfo: ''
  });
  const dispatch = useDispatch();
  const ability = useAbility(AbilityContext);

  const validateDateRange = parameters => {
    if (Object.keys(parameters).length <= 0) {
      dispatch(sendAlert({ kind: 'error', message: 'Debes seleccionar una fecha' }));
      return false;
    }
    if (!parameters.date_from) {
      dispatch(sendAlert({ kind: 'error', message: 'Debes seleccionar una fecha de Inicio' }));
      return false;
    }
    if (!parameters.date_to) {
      dispatch(sendAlert({ kind: 'error', message: 'Debes seleccionar una fecha de Término' }));
      return false;
    }
    const dateFrom = moment(parameters.date_from, 'DD/MM/YYYY');
    const dateTo = moment(parameters.date_to, 'DD/MM/YYYY');
    const months = dateTo.diff(dateFrom, 'months');

    if (months > 2) {
      dispatch(sendAlert({ kind: 'error', message: 'El rango de la fechas no puedes exceder de 3 meses' }));
      return false;
    }

    return true;
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    setSimpleModalShow(false);
    setModalShow(false);
    setOnRequest(false);
  };

  const handleSuccessExport = response => {
    setModalShow(false);
    downloadFile(response);
    setOnRequest(false);
  };

  const handleDownloadCostCenter = () => {
    setOnRequest(true);

    exportCostCentersRequest({
      dispatch,
      successCallback: handleSuccessExport,
      failureCallback: handleFailureRequest
    });
  };

  const handleDownloadBookkeeper = () => {
    setOnRequest(true);

    exportBookkeepersRequest({
      dispatch,
      successCallback: handleSuccessExport,
      failureCallback: handleFailureRequest
    });
  };

  const handleDownloadBranchOffice = () => {
    setOnRequest(true);
    exportBranchOfficesRequest({
      dispatch,
      successCallback: handleSuccessExport,
      failureCallback: handleFailureRequest
    });
  };

  const handleDownloadSubsidiary = () => {
    setOnRequest(true);
    exportSubsidiariesRequest({
      dispatch,
      successCallback: handleSuccessExport,
      failureCallback: handleFailureRequest
    });
  };

  const handleDownloadCertification = () => {
    setOnRequest(true);
    exportCertificationsRequest({
      dispatch,
      successCallback: handleSuccessExport,
      failureCallback: handleFailureRequest
    });
  };

  const handleDownloadJobManagement = () => {
    setOnRequest(true);
    exportJobManagementsRequest({
      dispatch,
      successCallback: handleSuccessExport,
      failureCallback: handleFailureRequest
    });
  };

  const handleDownloadJobTitle = () => {
    setOnRequest(true);
    exportJobTitleRequest({
      dispatch,
      successCallback: handleSuccessExport,
      failureCallback: handleFailureRequest
    });
  };

  const handleDownloadEmployee = () => {
    setOnRequest(true);
    exportEmployeesRequest({
      dispatch,
      successCallback: handleSuccessExport,
      failureCallback: handleFailureRequest
    });
  };

  const handleDownloadFamilyGroup = () => {
    setOnRequest(true);
    exportFamilyGroupsRequest({
      dispatch,
      successCallback: handleSuccessExport,
      failureCallback: handleFailureRequest
    });
  };

  const handleDownloadContract = () => {
    setOnRequest(true);
    exportContractsRequest({
      dispatch,
      successCallback: handleSuccessExport,
      failureCallback: handleFailureRequest
    });
  };

  const handleDownloadEmployeePrevision = () => {
    setOnRequest(true);
    exportEmployeePrevisionsRequest({
      dispatch,
      successCallback: handleSuccessExport,
      failureCallback: handleFailureRequest
    });
  };

  const handleDownloadBalance = balanceParams => {
    setOnRequest(true);
    exportBalancesRequest({
      dispatch,
      params: balanceParams,
      successCallback: handleSuccessExport,
      failureCallback: handleFailureRequest
    });
  };

  const handleDownloadContractBalance = balanceParams => {
    setOnRequest(true);
    exportContractBalancesRequest({
      dispatch,
      params: balanceParams,
      successCallback: handleSuccessExport,
      failureCallback: handleFailureRequest
    });
  };

  const handleDownloadShift = shiftParams => {
    setOnRequest(true);
    exportShiftRequest({
      dispatch,
      params: shiftParams,
      successCallback: handleSuccessExport,
      failureCallback: handleFailureRequest
    });
  };

  const handleDownloadAbsence = () => {
    setOnRequest(true);
    exportAbsencesRequest({
      dispatch,
      successCallback: handleSuccessExport,
      failureCallback: handleFailureRequest
    });
  };

  const handleDownloadPermission = () => {
    setOnRequest(true);
    exportPermissionsRequest({
      dispatch,
      successCallback: handleSuccessExport,
      failureCallback: handleFailureRequest
    });
  };

  const handleDownloadVacation = vacationParams => {
    setOnRequest(true);
    exportVacationsRequest({
      dispatch,
      params: vacationParams,
      successCallback: handleSuccessExport,
      failureCallback: handleFailureRequest
    });
  };

  const handleDownloadLoan = () => {
    setOnRequest(true);
    exportLoansRequest({
      dispatch,
      successCallback: handleSuccessExport,
      failureCallback: handleFailureRequest
    });
  };

  const handleDownloadAdvancePayment = () => {
    setOnRequest(true);
    exportAdvancePaymentsRequest({
      dispatch,
      successCallback: handleSuccessExport,
      failureCallback: handleFailureRequest
    });
  };

  const handleDownloadAttendance = attendaceParams => {
    const isValid = validateDateRange(attendaceParams);
    if (isValid) {
      setOnRequest(true);
      exportAttendanceRequest({
        params: attendaceParams,
        dispatch,
        successCallback: handleSuccessExport,
        failureCallback: handleFailureRequest
      });
    }
  };

  const handleDownloadActivityAttendance = attendaceParams => {
    const isValid = validateDateRange(attendaceParams);
    if (isValid) {
      setOnRequest(true);
      exportActivityAttendanceRequest({
        params: { ...attendaceParams, filter_by_company: currentCompany.id },
        dispatch,
        successCallback: handleSuccessExport,
        failureCallback: handleFailureRequest
      });
    }
  };

  const handleImportModalExceptions = error => {
    if (error?.response?.status === 422) {
      const alertInfo = <pre>{error?.response?.data?.message}</pre>;
      setCurrentCard({ ...currentCard, alertInfo });
      setAlertModalShow(true);
    } else {
      dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    }
  };

  const setImportModal = card => {
    setSimpleModalShow(true);
    setCurrentCard(card);
    switch (card.model) {
      case 'Bookkeeper':
        setOnDropUploaded(() => preImportBookkeeperRequest);
        setHandleTemplate(() => importTemplateBookkeeperRequest);
        setHandleImport(() => importBookkeeperRequest);
        break;
      case 'BranchOffice':
        setOnDropUploaded(() => preImportBranchOfficeRequest);
        setHandleTemplate(() => importTemplateBranchOfficeRequest);
        setHandleImport(() => importBranchOfficeRequest);
        break;
      case 'Subsidiary':
        setOnDropUploaded(() => preImportSubsidiaryRequest);
        setHandleTemplate(() => importTemplateSubsidiaryRequest);
        setHandleImport(() => importSubsidiaryRequest);
        break;
      case 'Certification':
        setOnDropUploaded(() => preImportCertificationRequest);
        setHandleTemplate(() => importTemplateCertificationRequest);
        setHandleImport(() => importCertificationRequest);
        break;
      case 'CostCenter':
        setOnDropUploaded(() => preImportCostCenterRequest);
        setHandleTemplate(() => importTemplateCostCenterRequest);
        setHandleImport(() => importCostCenterRequest);
        break;
      case 'JobManagement':
        setOnDropUploaded(() => preImportJobManagementRequest);
        setHandleTemplate(() => importTemplateJobManagementRequest);
        setHandleImport(() => importJobManagementRequest);
        break;
      case 'JobTitle':
        setOnDropUploaded(() => preImportJobTitleRequest);
        setHandleTemplate(() => importTemplateJobTitleRequest);
        setHandleImport(() => importJobTitleRequest);
        break;
      case 'Employee':
        setOnDropUploaded(() => preImportEmployeeRequest);
        setHandleTemplate(() => importTemplateEmployeeRequest);
        setHandleImport(() => importEmployeeRequest);
        setSendNotifications(true);
        break;
      case 'FamilyGroup':
        setOnDropUploaded(() => preImportFamilyGroupRequest);
        setHandleTemplate(() => importTemplateFamilyGroupRequest);
        setHandleImport(() => importFamilyGroupRequest);
        break;
      case 'Contract':
        setOnDropUploaded(() => preImportContractRequest);
        setHandleTemplate(() => importTemplateContractRequest);
        setHandleImport(() => importContractRequest);
        break;
      case 'EmployeePrevision':
        setOnDropUploaded(() => preImportEmployeePrevisionRequest);
        setHandleTemplate(() => importTemplateEmployeePrevisionRequest);
        setHandleImport(() => importEmployeePrevisionRequest);
        break;
      case 'Balance':
        setParams({ filter_type: card.title === 'haberes' ? 'asset' : 'discount' });
        setOnDropUploaded(() => preImportBalanceRequest);
        setHandleTemplate(() => importTemplateBalanceRequest);
        setHandleImport(() => importBalanceRequest);
        break;
      case 'ContractBalance':
        setParams({ filter_type: card.title === 'haberes contrato' ? 'asset' : 'discount' });
        setOnDropUploaded(() => preImportContractBalanceRequest);
        setHandleTemplate(() => importTemplateContractBalanceRequest);
        setHandleImport(() => importContractBalanceRequest);
        break;
      case 'Shift':
        if (card.title === 'turnos semanales') {
          setParams({ shift_type: 'weekly' });
        } else if (card.title === 'turnos mensuales') {
          setParams({ shift_type: 'monthly' });
        } else {
          setParams({ shift_type: 'rotative' });
        }
        setOnDropUploaded(() => preImportShiftRequest);
        setHandleTemplate(() => importTemplateShiftRequest);
        setHandleImport(() => importShiftRequest);
        break;
      case 'Absence':
        setOnDropUploaded(() => preImportAbsenceRequest);
        setHandleTemplate(() => importTemplateAbsenceRequest);
        setHandleImport(() => importAbsenceRequest);
        // setSendNotifications(true);
        break;
      case 'Permission':
        setOnDropUploaded(() => preImportPermissionRequest);
        setHandleTemplate(() => importTemplatePermissionRequest);
        setHandleImport(() => importPermissionRequest);
        // setSendNotifications(true);
        break;
      case 'Vacation':
        setParams(
          card.title === 'vacaciones'
            ? { filter_vacation_exception: 'administrative_days' }
            : { filter_vacation_type: 'administrative_days' }
        );
        setOnDropUploaded(() => preImportVacationRequest);
        setHandleTemplate(() => importTemplateVacationRequest);
        setHandleImport(() => importVacationRequest);
        // setSendNotifications(true);
        break;
      case 'Loan':
        setOnDropUploaded(() => preImportLoanRequest);
        setHandleTemplate(() => importTemplateLoanRequest);
        setHandleImport(() => importLoanRequest);
        break;
      case 'AdvancePayment':
        setAlertModalShow(true);
        setOnDropUploaded(() => preImportAdvancePaymentRequest);
        setHandleTemplate(() => importTemplateAdvancePaymentRequest);
        setHandleImport(() => importAdvancePaymentRequest);
        break;
      case 'Attendance':
        setOnDropUploaded(() => preImportAttendanceRequest);
        setHandleTemplate(() => importTemplateAttendanceRequest);
        setHandleImport(() => importAttendanceRequest);
        setSendNotifications(true);
        break;
      case 'ActivityAttendance':
        setOnDropUploaded(() => preImportActivityAttendanceRequest);
        setHandleTemplate(() => importTemplateActivityAttendanceRequest);
        setHandleImport(() => importActivityAttendanceRequest);
        setSendNotifications(true);
        break;
      default:
        setSimpleModalShow(false);
        setCurrentCard({});
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  const setExportModal = card => {
    setModalShow(true);
    setCurrentCard(card);
    switch (card.model) {
      case 'Bookkeeper':
        setModalAction(() => handleDownloadBookkeeper);
        break;
      case 'BranchOffice':
        setModalAction(() => handleDownloadBranchOffice);
        break;
      case 'Subsidiary':
        setModalAction(() => handleDownloadSubsidiary);
        break;
      case 'Certification':
        setModalAction(() => handleDownloadCertification);
        break;
      case 'CostCenter':
        setModalAction(() => handleDownloadCostCenter);
        break;
      case 'JobManagement':
        setModalAction(() => handleDownloadJobManagement);
        break;
      case 'JobTitle':
        setModalAction(() => handleDownloadJobTitle);
        break;
      case 'Employee':
        setModalAction(() => handleDownloadEmployee);
        break;
      case 'FamilyGroup':
        setModalAction(() => handleDownloadFamilyGroup);
        break;
      case 'Contract':
        setModalAction(() => handleDownloadContract);
        break;
      case 'EmployeePrevision':
        setModalAction(() => handleDownloadEmployeePrevision);
        break;
      case 'Balance':
        setParams({ filter_type: card.title === 'haberes' ? 'asset' : 'discount' });
        setModalAction(() => handleDownloadBalance);
        break;
      case 'ContractBalance':
        setParams({ filter_type: card.title === 'haberes contrato' ? 'asset' : 'discount' });
        setModalAction(() => handleDownloadContractBalance);
        break;
      case 'Shift':
        if (card.title === 'turnos semanales') {
          setParams({ shift_type: 'weekly' });
        } else if (card.title === 'turnos mensuales') {
          setParams({ shift_type: 'monthly' });
        } else {
          setParams({ shift_type: 'rotative' });
        }
        setModalAction(() => handleDownloadShift);
        break;
      case 'Absence':
        setModalAction(() => handleDownloadAbsence);
        break;
      case 'Permission':
        setModalAction(() => handleDownloadPermission);
        break;
      case 'Loan':
        setModalAction(() => handleDownloadLoan);
        break;
      case 'AdvancePayment':
        setModalAction(() => handleDownloadAdvancePayment);
        break;
      case 'Vacation':
        setParams(
          card.title === 'vacaciones'
            ? { filter_vacation_exception: 'administrative_days' }
            : { filter_vacation_type: 'administrative_days' }
        );
        setModalAction(() => handleDownloadVacation);
        break;
      case 'Attendance':
        setModalAction(() => handleDownloadAttendance);
        break;
      case 'ActivityAttendance':
        setModalAction(() => handleDownloadActivityAttendance);
        break;
      default:
        setModalShow(false);
        setCurrentCard({});
        setParams({});
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };
  const parametersCards = [
    {
      title: 'áreas',
      model: 'JobManagement',
      visible: ability.can('import', 'JobManagement') || ability.can('export', 'JobManagement')
    },
    {
      title: 'Lugares de Prestación de Servicios',
      model: 'BranchOffice',
      visible: ability.can('import', 'BranchOffice') || ability.can('export', 'BranchOffice')
    },
    {
      title: 'Sucursales',
      model: 'Subsidiary',
      visible: ability.can('import', 'Subsidiary') || ability.can('export', 'Subsidiary')
    },
    {
      title: 'centros de costo',
      model: 'CostCenter',
      visible: ability.can('import', 'CostCenter') || ability.can('export', 'CostCenter')
    },
    {
      title: 'cuentas contables',
      model: 'Bookkeeper',
      visible: ability.can('import', 'Bookkeeper') || ability.can('export', 'Bookkeeper')
    },
    {
      title: 'certificaciones',
      model: 'Certification',
      visible: ability.can('import', 'Certification') || ability.can('export', 'Certification')
    },
    {
      title: 'cargos',
      model: 'JobTitle',
      visible: ability.can('import', 'JobTitle') || ability.can('export', 'JobTitle')
    }
  ];
  const employeeCards = [
    {
      title: 'fichas',
      model: 'Employee',
      visible: ability.can('import', 'Employee') || ability.can('export', 'Employee')
    },
    {
      title: 'contratos',
      model: 'Contract',
      visible: ability.can('import', 'Contract') || ability.can('export', 'Contract')
    },
    {
      title: 'grupos familiares',
      model: 'FamilyGroup',
      visible: ability.can('import', 'FamilyGroup') || ability.can('export', 'FamilyGroup')
    },
    {
      title: 'previsiones',
      model: 'EmployeePrevision',
      visible: ability.can('import', 'EmployeePrevision') || ability.can('export', 'EmployeePrevision')
      // },
      // {
      //   title: 'APV',
      //   model: 'VoluntaryPrevision',
      //   visible: ability.can('import', 'VoluntaryPrevision') || ability.can('export', 'VoluntaryPrevision')
    }
  ];
  const contractRemunerationCards = [
    {
      title: 'haberes contrato',
      model: 'ContractBalance',
      visible: ability.can('import_asset', 'ContractBalance') || ability.can('export_asset', 'ContractBalance')
    },
    {
      title: 'descuentos contrato',
      model: 'ContractBalance',
      visible: ability.can('import_discount', 'ContractBalance') || ability.can('export_discount', 'ContractBalance')
    }
  ];
  const customRemunerationCards = [
    {
      title: 'haberes',
      model: 'Balance',
      visible: ability.can('import_asset', 'Balance') || ability.can('export_asset', 'Balance')
    },
    {
      title: 'descuentos',
      model: 'Balance',
      visible: ability.can('import_discount', 'Balance') || ability.can('export_discount', 'Balance')
    }
  ];

  const loanTab = advanceSettings.filter(asItem => asItem.code === 'loan_request').some(x => x.textValue === 'true');
  const advancePaymentsTab = advanceSettings
    .filter(asItem => asItem.code === 'advance_payment_request')
    .some(x => x.textValue === 'true');
  const remunerationCards = [
    {
      title: 'préstamos',
      model: 'Loan',
      visible: (ability.can('import', 'Loan') || ability.can('export', 'Loan')) && loanTab
    },
    {
      title: 'anticipos',
      model: 'AdvancePayment',
      alertInfo: 'Importar anticipos no considera la fecha límite para solicitar anticipos, definida en herramientas.',
      visible:
        (ability.can('import', 'AdvancePayment') || ability.can('export', 'AdvancePayment')) && advancePaymentsTab
    }
  ];

  const administrativeDaysCard = advanceSettings
    .filter(asItem => ['administrative_days'].includes(asItem.code))
    .some(x => x.textValue === 'true');
  const vacationsCard = advanceSettings
    .filter(asItem => ['legal_holiday', 'progressive_holiday', 'additional_days'].includes(asItem.code))
    .some(x => x.textValue === 'true');
  const permissionsCard = advanceSettings
    .filter(asItem => ['paid_leave', 'without_paid_leave'].includes(asItem.code))
    .some(x => x.textValue === 'true');
  const activityAttendance = advanceSettings
    .filter(asItem => asItem.code === 'activity_attendances')
    .some(x => x.textValue === 'true');
  const attendanceCards = [
    {
      title: 'ausentismo',
      model: 'Absence',
      visible: ability.can('import', 'Absence') || ability.can('export', 'Absence')
    },
    {
      title: 'permisos',
      model: 'Permission',
      visible: (ability.can('import', 'Permission') || ability.can('export', 'Permission')) && permissionsCard
    },
    {
      title: 'vacaciones',
      model: 'Vacation',
      visible: (ability.can('import', 'Vacation') || ability.can('export', 'Vacation')) && vacationsCard
    },
    {
      title: 'días administrativos',
      model: 'Vacation',
      visible: (ability.can('import', 'Vacation') || ability.can('export', 'Vacation')) && administrativeDaysCard
    },
    {
      title: 'marcaciones de asistencia',
      model: 'Attendance',
      visible: ability.can('import', 'Attendance') || ability.can('export', 'Attendance')
    },
    {
      title: 'otras marcaciones de asistencia',
      model: 'ActivityAttendance',
      visible: (ability.can('import', 'ActivityAttendance') || ability.can('export', 'ActivityAttendance')) && activityAttendance
    }
  ];

  return (
    <>
      {onRequest && (
        <div className="containerSpinnerLoad fix-middle center-spinner position-fixed">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <Row className="mt-4 mb-4">
        <Col xs={12}>
          <h2 className="mt-3 m-top">Importar y Exportar</h2>
        </Col>
      </Row>
      <Row className={`${onRequest && 'bg-opacity'}`}>
        <Col xs={12}>
          <h4 className="text-uppercase margin-fix">Parámetros de empresa</h4>
        </Col>
        {parametersCards
          .filter(card => card.visible)
          .map(card => (
            <Col key={card.title} xs={12} md={6} xl={4}>
              <ImporterCard handleImport={setImportModal} handleExport={setExportModal} card={card} />
            </Col>
          ))}
        <Col xs={12}>
          <h4 className="text-uppercase margin-fix">Trabajadores</h4>
        </Col>
        {employeeCards
          .filter(card => card.visible)
          .map(card => (
            <Col key={card.title} xs={12} md={6} xl={4}>
              <ImporterCard handleImport={setImportModal} handleExport={setExportModal} card={card} />
            </Col>
          ))}
        {contractRemunerationCards
          .filter(card => card.visible)
          .map(card => (
            <Col key={card.title} xs={12} md={6} xl={4}>
              <ImporterCard
                handleImport={setImportModal}
                handleExport={setExportModal}
                card={card}
                importText={`import_${card.title === 'haberes contrato' ? 'asset' : 'discount'}`}
                exportText={`export_${card.title === 'haberes contrato' ? 'asset' : 'discount'}`}
              />
            </Col>
          ))}
        <Col xs={12}>
          <h4 className="text-uppercase margin-fix">Remuneraciones</h4>
        </Col>
        {customRemunerationCards
          .filter(card => card.visible)
          .map(card => (
            <Col key={card.title} xs={12} md={6} xl={4}>
              <ImporterCard
                handleImport={setImportModal}
                handleExport={setExportModal}
                card={card}
                importText={`import_${card.title === 'haberes' ? 'asset' : 'discount'}`}
                exportText={`export_${card.title === 'haberes' ? 'asset' : 'discount'}`}
              />
            </Col>
          ))}
        {remunerationCards
          .filter(card => card.visible)
          .map(card => (
            <Col key={card.title} xs={12} md={6} xl={4}>
              <ImporterCard handleImport={setImportModal} handleExport={setExportModal} card={card} />
            </Col>
          ))}
        <Col xs={12}>
          <h4 className="text-uppercase margin-fix">Asistencia</h4>
        </Col>
        {attendanceCards
          .filter(card => card.visible)
          .map(card => (
            <Col key={card.title} xs={12} md={6} xl={4}>
              <ImporterCard handleImport={setImportModal} handleExport={setExportModal} card={card} />
            </Col>
          ))}
      </Row>
      <DefaultModal
        title={`Exportar ${currentCard.title}`}
        body={
          (currentCard.model === 'Attendance' || currentCard.model === 'ActivityAttendance') ? (
            <AttendanceFilterForm setParams={setParams} />
          ) : (
            `¿Estás seguro que deseas descargar ${currentCard.title}?`
          )
        }
        show={modalShow}
        handleClose={() => setModalShow(false)}
        handleConfirm={() => modalAction(params)}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        disabled={onRequest}
      />
      <SimpleCenteredModal
        title={`Importar ${currentCard.title}`}
        body={
          <ImportModal
            onDropUploaded={onDropUploaded}
            handleTemplate={handleTemplate}
            onHide={handleImport}
            sendNotifications={sendNotifications}
            hideModal={() => {
              setSimpleModalShow(false);
              setSendNotifications(false);
            }}
            params={params}
            handleExceptions={handleImportModalExceptions}
          />
        }
        show={simpleModalShow}
        onHide={() => {
          setSimpleModalShow(false);
          setSendNotifications(false);
        }}
      />
      <DefaultModal
        title="Información Relevante"
        body={currentCard.alertInfo}
        show={alertModalShow}
        withClose={false}
        handleConfirm={() => setAlertModalShow(false)}
        handleClose={() => setAlertModalShow(false)}
        titleBtnSave="Confirmar"
        scrollable
      />
    </>
  );
};

export default ImporterIndex;

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';
import { useAbility } from '@casl/react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  massiveRejectWorkflowRequestRequest,
  massiveSingWorkflowRequestRequest,
  signWorkflowRequestRequest
} from '../../../requests/workflowRequests';
import { sendAlert } from '../../../actions/utils';
import { downloadFile } from '../../../services/utils';
import VacationInfo from '../../Profile/Vacation/VacationInfo';
import {
  approveVacationRequest,
  deleteVacationRequest,
  exportVacationsRequest,
  importTemplateVacationRequest,
  importVacationRequest,
  debounceIndexVacationsRequest,
  preImportVacationRequest,
  rejectVacationRequest
} from '../../../requests/vacations';
import { searchEmployeesWithAvailableVacationsRequest } from '../../../requests/employees';
import VacationRejectForm from './VacationRejectForm';
import SignatureKeyForm from '../../DocumentPreview/SignatureKeyForm';
import {
  ButtonTooltip,
  ComponentDataTable,
  DefaultModal,
  DocumentActions,
  Icon,
  ImportModal,
  Reminder
} from '../../../components';
import { AbilityContext } from '../../../config/abilityContext';
import WorkflowRequestRejectMasiveForm from '../../WorkflowRequest/WorkflowRequestRejectMasiveForm';

const VacationDataTable = ({
  columns,
  customParams,
  preName,
  moreData,
  setMoreData,
  defaultStartDate,
  defaultEndDate,
  withImport,
  withMassActions,
  adminDays,
  withWorkflow = true
}) => {
  const ability = useAbility(AbilityContext);
  const [amount, setAmount] = useState(0);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalAction, setModalAction] = useState(() => null);
  const [modalButtons, setModalButtons] = useState(true);
  const [modalItem, setModalItem] = useState({});
  const [onRequest, setOnRequest] = useState(true);
  const [query, setQuery] = useState({});
  const [reminderItem, setReminderItem] = useState({});
  const [reminderShow, setReminderShow] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [vacations, setVacations] = useState([]);
  const [isDisabled, setIsDisable] = useState(false);

  const handleModalClose = () => {
    setModalShow(false);
    setModalTitle('');
    setModalBody('');
    setOnRequest(false);
  };

  const cleanSelectedData = () => {
    setSelectedCount(0);
    setSelectedRows([]);
    setClearSelectedRows(!clearSelectedRows);
  };

  const handleSuccessAction = message => {
    handleModalClose();
    setMoreData(!moreData);
    dispatch(sendAlert({ kind: 'success', message }));
    cleanSelectedData();
  };

  const handleSuccessRequest = status => {
    let message = '';
    switch (status) {
      case 'approved':
        message = 'Solicitud aprobada con éxito';
        break;
      case 'rejected':
        message = 'Solicitud rechazada con éxito';
        break;
      default:
        message = 'No action';
        break;
    }

    dispatch(sendAlert({ kind: 'success', message }));
    setMoreData(!moreData);
    cleanSelectedData();
    handleModalClose();
  };

  const handleApproveRequest = item => {
    const vacationId = item.id;
    const sendParams = snakeCaseKeys({ vacation: item });
    setIsDisable(false);
    setOnRequest(true);

    approveVacationRequest(vacationId, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: () => handleSuccessRequest('approved'),
      callback: () => setOnRequest(false)
    });
  };

  const handleMassiveApproveRequest = () => {
    setIsDisable(true);
    const elementsSummaryWorkflowIds = selectedRows
      .filter(element => element.status === 'pending' && element.review_turn === true)
      .map(element => element.workflow_request_id);
    massiveSingWorkflowRequestRequest({
      dispatch,
      params: {
        workflowsIds: elementsSummaryWorkflowIds
      },
      successCallback: () => {
        handleSuccessRequest('approved');
      }
    });
  };

  const massApproveBody = () => {
    const selectedItemCount = selectedRows.filter(
      element => element.status === 'pending' && element.review_turn === true
    ).length;
    const request = adminDays ? 'día(s) administrativo(s)' : 'vacacion(es)';

    return `haz seleccionado ${selectedItemCount} ${request}, ¿Quieres aprobar estas solicitudes?`;
  };

  const handleSuccessIndex = response => {
    const responseVacations = response.data.data;
    const { amount: responseAmount } = response.data.metadata;
    setVacations(responseVacations);
    setAmount(responseAmount);
    setOnRequest(false);
    cleanSelectedData();
  };

  const handleIndexRequest = params => {
    setOnRequest(true);
    const sendParams = {
      ...params,
      ...customParams
    };
    if (
      !sendParams.sort_employees &&
      !sendParams.sort_job_titles &&
      !sendParams.sort_job_managements &&
      !sendParams.sort_status
    ) {
      sendParams.sort_column = params.sort_column || customParams.sort_column || 'start_date';
    }
    setQuery(sendParams);
    if (customParams.vacationsAvailableType) {
      const myParams = {
        vacationsAvailableType: customParams.vacationsAvailableType,
        active: true,
        active_contracts: true
      };
      searchEmployeesWithAvailableVacationsRequest({
        dispatch,
        params: myParams,
        successCallback: handleSuccessIndex,
        callback: setOnRequest(false)
      });
    } else {
      debounceIndexVacationsRequest({
        dispatch,
        params: sendParams,
        successCallback: handleSuccessIndex,
        callback: setOnRequest(false)
      });
    }
  };

  const handleOpenModal = (title, body) => {
    setModalTitle(title);
    setModalBody(body);
    setModalButtons(false);
    setModalAction(() => null);
    setModalShow(true);
  };

  const handleRejectRequest = (item, setSubmitting) => {
    const { id: vacationId } = item.vacation;
    const sendParams = snakeCaseKeys(item);

    setOnRequest(true);
    rejectVacationRequest(vacationId, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: () => handleSuccessRequest('rejected'),
      callback: () => {
        setOnRequest(false);
        setSubmitting(false);
      }
    });
  };

  const signDocument = (formValues, { workflowRequestId }, setSubmitting = () => null) => {
    signWorkflowRequestRequest(workflowRequestId, {
      dispatch,
      params: snakeCaseKeys(formValues),
      successCallback: () => handleSuccessRequest('approved'),
      callback: () => setSubmitting(false)
    });
  };

  const massSignBody = () => {
    const elementsSummaryWorkflowIds = selectedRows
      .filter(element => element.status === 'pending' && element.review_turn === true)
      .map(element => element.workflow_request_id);

    const massiveSingRequest = ({ signatureKey }, setSubmitting) => {
      setOnRequest(true);
      massiveSingWorkflowRequestRequest({
        dispatch,
        params: {
          workflowsIds: elementsSummaryWorkflowIds,
          signatureKey
        },
        successCallback: () => {
          handleSuccessRequest('approved');
        },
        callback: () => {
          setSubmitting(false);
          setOnRequest(false);
        }
      });
    };

    return <SignatureKeyForm formRequest={massiveSingRequest} onHide={handleModalClose} signatureKey="" />;
  };
  const handleApproveModal = item => {
    setModalTitle(`${item.requestReviewerSignature ? 'Firmar y' : ''} Aprobar Solicitud de Vacaciones`);
    if (item.requestReviewerSignature) {
      setModalBody(
        <SignatureKeyForm
          item={item}
          onHide={handleModalClose}
          formRequest={signDocument}
          signatureKey=""
          errorMessage={
            item.employeeTotalVacationByType < item.days
              ? `El colaborador ${item.employee.shortName} está solicitando ${item.days} días y solo tiene ${item.employeeTotalVacationByType} días disponibles. ¿Está seguro que desea aprobarlas/firmarlas?`
              : ''
          }
        />
      );
      setModalButtons(false);
      setModalAction(() => null);
    } else {
      setModalItem(item);
      setModalBody(
        <>
          {item.translatedVacationType !== 'Administrativos' && item.validEmployedLessThanYear && (
            <p className="text-danger text-center h4">
              Este empleado tiene menos de un año en la empresa <br />
              Presione confirmar para continuar con el proceso.
            </p>
          )}
          <VacationInfo item={item} showErrorMessage />
        </>
      );
      setModalButtons(true);
      setModalAction(() => handleApproveRequest);
    }
    setModalShow(true);
  };

  const handleRejectModal = item => {
    const title = 'Rechazar solicitud';
    const body = (
      <VacationRejectForm vacation={item} formRequest={handleRejectRequest} handleModalClose={handleModalClose} />
    );
    handleOpenModal(title, body);
  };

  const handleMassiveRejectWorkflow = () => {
    const massiveRejectWorkflowRequest = ({ rejectObservation }) => {
      const elementsSummaryWorkflowIds = selectedRows
        .filter(element => element.status === 'pending' && element.review_turn === true)
        .map(element => element.workflow_request_id);

      massiveRejectWorkflowRequestRequest({
        dispatch,
        params: {
          workflows_ids: elementsSummaryWorkflowIds,
          observation: rejectObservation
        },
        successCallback: () => {
          handleSuccessAction('Solicitud Rechazada con éxito');
        }
      });
    };

    setModalButtons(false);
    setModalTitle('Rechazo Masivo');
    setModalBody(
      <WorkflowRequestRejectMasiveForm formRequest={massiveRejectWorkflowRequest} handleModalClose={handleModalClose} />
    );
    setModalShow(true);
  };

  const handleModalShow = item => {
    setModalTitle(`Solicitud de ${item.translatedVacationType === 'Administrativos' ? 'Permiso' : 'Vacaciones'}`);
    setModalBody(<VacationInfo item={item} />);
    setModalButtons(false);
    setModalShow(true);
  };

  const handleConfirmModal = item => {
    setModalItem(item);
    setModalTitle('Eliminar Solicitud de Vacaciones');
    setModalBody('¿Estás seguro que deseas eliminar esta solicitud?');
    setModalButtons(true);
    setModalShow(true);
  };

  const handleSuccessRemove = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Solicitud eliminada con éxito' }));
    setMoreData(!moreData);
  };

  const handleDeleteRequest = item => {
    setOnRequest(true);
    deleteVacationRequest(item.id, {
      dispatch,
      successCallback: handleSuccessRemove,
      callback: () => handleModalClose()
    });
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'job_title':
        return { sort_job_titles: name };
      case 'job_management':
        return { sort_job_managements: name };
      case 'employee':
        return { sort_employees: name };
      case 'status':
        return { sort_status: name };
      case 'permission_type':
        return { sort_permission_type: name };
      default:
        return { sort_column: name };
    }
  };

  const handleDownload = () => {
    setOnRequest(true);
    exportVacationsRequest({
      dispatch,
      params: query,
      successCallback: response => {
        setModalShow(false);
        downloadFile(response);
      },
      callback: () => setOnRequest(false)
    });
  };

  const downloadModal = () => {
    const name = preName === 'administrative_days' ? 'días administrativos' : 'vacaciones';
    setModalTitle(`Exportar ${name}`);
    setModalBody(`¿Estás seguro que deseas descargar ${name}?`);
    setModalAction(() => handleDownload);
    setModalButtons(true);
    setModalShow(true);
  };

  const handleSelectedRows = item => {
    setSelectedCount(item.selectedCount);
    setSelectedRows(item.selectedRows);
  };

  const handleActions = (item, action) => {
    switch (action) {
      case 'show':
        handleModalShow(camelCaseRecursive(item));
        break;
      case 'approved':
        handleApproveModal(camelCaseRecursive(item));
        break;
      case 'rejected':
        handleRejectModal(camelCaseRecursive(item));
        break;
      case 'destroy':
        handleConfirmModal(item);
        setModalAction(() => handleDeleteRequest);
        break;
      case 'resend':
        setReminderShow(true);
        setReminderItem(item);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  const handleImportModalExceptions = error => {
    if (error?.response?.status === 422) {
      const alertInfo = <pre>{error?.response?.data?.message}</pre>;
      setModalTitle('Información Relevante');
      setModalShow(true);
      setModalBody(alertInfo);
      setModalAction(() => handleModalClose);
    } else {
      dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    }
  };

  const handleMassAction = action => {
    const elementsSummary = selectedRows.filter(
      element => element.status === 'pending' && element.review_turn === true
    );

    let title = '';
    let body = '';

    switch (action) {
      case 'massAprove':
        if (elementsSummary.length > 0) {
          const elementsSummarySignNeeded = elementsSummary.some(
            element => element.request_reviewer_signature === true
          );
          title = `${elementsSummarySignNeeded ? 'Firmar y' : ''} Aprobar solicitud de ${
            adminDays ? 'Días Administrativos' : 'Vacaciones'
          } `;
          body = elementsSummarySignNeeded ? massSignBody() : massApproveBody();

          if (elementsSummarySignNeeded) {
            handleOpenModal(title, body);
          } else {
            setModalTitle(title);
            setModalBody(body);
            setModalButtons(true);
            setModalAction(() => handleMassiveApproveRequest);
            setModalShow(true);
          }
        }
        break;
      case 'import':
        title = `Importar ${preName === 'administrative_days' ? 'días administrativos' : 'vacaciones'}`;
        body = (
          <ImportModal
            onDropUploaded={preImportVacationRequest}
            handleTemplate={importTemplateVacationRequest}
            onHide={importVacationRequest}
            hideModal={handleModalClose}
            params={preName === 'administrative_days' ? { filter_vacation_type: 'administrative_days' } : {}}
            updateData={() => setMoreData(!moreData)}
            handleExceptions={handleImportModalExceptions}
          />
        );
        handleOpenModal(title, body);
        break;
      case 'export':
        downloadModal();
        break;
      case 'massReject':
        if (elementsSummary.length > 0) {
          handleMassiveRejectWorkflow();
        }
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  return (
    <>
      <ComponentDataTable
        clearSelectedRows={clearSelectedRows}
        columns={columns(handleActions, ability)}
        data={vacations}
        defaultEndDate={defaultEndDate}
        defaultStartDate={defaultStartDate}
        handleSortCase={sortColumnCase}
        massActions={
          <>
            {ability.can('export', 'Vacation') && (
              <ButtonTooltip
                variant="circle-primary"
                className="mr-2 btn-circle mb-3"
                text="Exportar"
                onClick={() => {
                  handleMassAction('export');
                }}
              >
                <Icon icon="download-2" />
              </ButtonTooltip>
            )}
            {ability.can('import', 'Vacation') && withImport && (
              <ButtonTooltip
                variant="circle-primary"
                className="mr-2 btn-circle mb-3"
                text="Importar"
                onClick={() => {
                  handleMassAction('import');
                }}
              >
                <Icon icon="cloud-upload-outline" />
              </ButtonTooltip>
            )}
            {ability.can('workflow_action', 'Vacation') && withWorkflow && (
              <DocumentActions handleClick={handleMassAction} disabled={!selectedCount || onRequest} />
            )}
          </>
        }
        moreData={moreData}
        onRequest={onRequest}
        onRowClicked={item => handleActions(item, 'show')}
        onSelectedRowsChange={handleSelectedRows}
        pointerOnHover
        preName={preName}
        rangePicker
        resourceRequest={handleIndexRequest}
        selectableRows={withMassActions}
        totalRows={amount}
        withStartDate
        withEndDate
        withMassActions={withMassActions}
      />
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        withConfirm={modalButtons}
        withClose={modalButtons}
        handleClose={handleModalClose}
        handleConfirm={() => modalAction(modalItem)}
        disabled={onRequest || isDisabled}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
      <Reminder
        item={reminderItem}
        show={reminderShow}
        modalShow={setReminderShow}
        moreData={moreData}
        setOnRequest={setOnRequest}
        setMoreData={setMoreData}
      />
    </>
  );
};

VacationDataTable.propTypes = {
  preName: PropTypes.string,
  moreData: PropTypes.bool.isRequired,
  setMoreData: PropTypes.func.isRequired,
  defaultStartDate: PropTypes.instanceOf(moment),
  defaultEndDate: PropTypes.instanceOf(moment),
  withImport: PropTypes.bool,
  adminDays: PropTypes.bool
};

VacationDataTable.defaultProps = {
  defaultStartDate: null,
  defaultEndDate: null,
  withImport: false,
  adminDays: false,
  preName: 'vacation'
};

export default VacationDataTable;

import React from 'react';
import memoize from 'memoize-one';
import { ButtonTooltip, Icon, BasicDropdown } from '../../../components';

const complaintsActions = (clickHandler, complaintMenu, row) => {
  const actions = [];

  if (complaintMenu[2]) {
    actions.push({
      key: 'show',
      title: 'Ver',
      icon: <Icon icon="eye" height="16px" width="16px" style={{ marginRight: 5 }} />,
      props: { onClick: () => clickHandler(row, 'show') }
    });
  }

  actions.push({
    key: 'destroy',
    title: 'Eliminar',
    icon: <Icon icon="trash" height="16px" width="16px" style={{ marginRight: 5 }} />,
    props: {
      onClick: () => clickHandler(row, 'destroy'),
      disabled: row.status !== 'unassigned'
    }
  });

  if (complaintMenu[0]) {
    actions.push({
      key: 'assign',
      title: 'Asignar',
      icon: <Icon icon="plus" height="16px" width="16px" style={{ marginRight: 5 }} />,
      props: {
        onClick: () => clickHandler(row, 'assign'),
        disabled: row.status === 'in_progress' || row.status === 'resolved'
      }
    });
  }

  if (complaintMenu[1]) {
    actions.push({
      key: 'conclude',
      title: 'Cerrar',
      icon: <Icon icon="archive" height="16px" width="16px" style={{ marginRight: 5 }} />,
      props: {
        onClick: () => clickHandler(row, 'conclude'),
        disabled: row.status === 'resolved'
      }
    });
  }

  return actions;
};

const complaintsColumns = memoize((clickHandler, complaintMenu, rrhh = false) => {
  const columns = rrhh
    ? [
        {
          name: 'CODIGO DE LA DENUNCIA',
          selector: 'complaintCode',
          sortable: true,
          grow: '3',
          cell: row => <p>{row.unique_code}</p>
        },
        {
          name: 'FECHA',
          selector: 'date',
          sortable: true,
          grow: '2',
          cell: row => <p className="text-center">{row.date}</p>
        },
        {
          name: 'ASIGNADO',
          selector: 'assignedTo',
          grow: '3',
          cell: row => <p>{row.assigned_to_name || 'Aun No Asignado'}</p>
        },
        {
          name: 'FECHA RESPUESTA',
          selector: 'responseDate',
          sortable: true,
          grow: '2',
          cell: row => <p>{row.response_date || 'Sin fecha de respuesta'}</p>
        },
        {
          name: 'ESTADO',
          selector: 'status',
          sortable: true,
          grow: '2',
          cell: item => <span>{item.translated_status}</span>
        },
        {
          name: 'ACCIONES',
          cell: row => (
            <BasicDropdown
              variant="outline-primary"
              noArrow
              block
              right
              titleDrop={
                <span className="align-middle">
                  Acciones <Icon icon="ellipsis-vertical" height="20px" width="20px" />
                </span>
              }
              items={complaintsActions(clickHandler, complaintMenu, row)}
            />
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          grow: '1',
          minWidth: '180px'
        }
      ]
    : [
        {
          name: 'FECHA',
          selector: 'date',
          sortable: true,
          grow: '2',
          cell: row => <p className="text-center">{row.date}</p>
        },
        {
          name: 'CODIGO DE LA DENUNCIA',
          selector: 'complaintCode',
          sortable: true,
          grow: '3',
          cell: row => <p>{row.unique_code}</p>
        },
        {
          name: 'CANAL DE DENUNCIA',
          selector: 'channel',
          sortable: true,
          grow: '2'
        },
        {
          name: 'ASIGNADO',
          selector: 'assignedTo',
          grow: '3',
          cell: row => <p>{row.assigned_to_name || 'Aun No Asignado'}</p>
        },
        {
          name: 'FECHA RESPUESTA',
          selector: 'responseDate',
          sortable: true,
          grow: '2',
          cell: row => <p>{row.response_date || 'Sin fecha de respuesta'}</p>
        },
        {
          name: 'ESTADO',
          selector: 'status',
          sortable: true,
          grow: '2',
          cell: item => <span>{item.translated_status}</span>
        },
        {
          name: 'ACCIONES',
          cell: row => (
            <>
              <ButtonTooltip
                onClick={() => clickHandler(row, 'show')}
                variant="circle-info"
                className="btn-circle"
                size="sm"
                text="Ver"
              >
                <Icon icon="eye" />
              </ButtonTooltip>
              <ButtonTooltip
                onClick={() => clickHandler(row, 'destroy')}
                variant="circle-danger"
                className="btn-circle"
                size="sm"
                text="Eliminar"
                disabled={row.status !== 'unassigned'}
              >
                <Icon icon="trash" />
              </ButtonTooltip>
            </>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          grow: '1',
          minWidth: '180px'
        }
      ];

  return columns;
});

export default complaintsColumns;

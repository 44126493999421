/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';

import { ApprovementStatus } from '../../../components';
import { handleDaysShow } from '../../../services/utils';

const vacationColumns = memoize(clickHandler => [
  {
    name: 'FECHA DE SOLICITUD',
    selector: 'created_at',
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'TIPO DE VACACIÓN',
    selector: 'vacation_type',
    cell: item => item.translated_vacation_type,
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'FECHA INICIO',
    selector: 'start_date',
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'FECHA TERMINO',
    selector: 'end_date',
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'FECHA RETORNO',
    selector: 'reincorporation_date',
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'TOTAL DÍAS',
    selector: 'days',
    cell: item => handleDaysShow(item, 'days'),
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => <ApprovementStatus status={item.status} onClick={() => clickHandler(item, 'show')} />,
    sortable: true,
    grow: '1',
    center: true
  }
]);

export default vacationColumns;

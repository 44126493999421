import React from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import { Button, Row, Col } from 'react-bootstrap';
import * as Yup from 'yup';
import { FormikRangePicker } from '../../../components';

const AttendancesControlClockFilter = props => {
  const { errors, touched, values } = props;

  return (
    <Form>
      <Row>
        <Col md={4}>
          <Field name="rangeDate">
            {({ field }) => (
              <FormikRangePicker
                {...field}
                name={field.name}
                abbr
                startDateName="startDate"
                endDateName="endDate"
                startDate={values.startDate}
                endDate={values.endDate}
                showClearDates
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row className="d-flex justify-content-end mt-4 mb-4">
        <Col md={2}>
          <Button className="btn-block" type="submit">
            PreVisualizar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const validationSchema = Yup.object().shape({
  rangeDate: Yup.array()
    .required('Debes seleccionar un rango de fechas')
    .rangedate(true)
});
const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  console.log(values);
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: () => ({
    rangeDate: ''
  }),
  validationSchema,
  handleSubmit,
  enableReinitialize: true
})(AttendancesControlClockFilter);
